import { useEffect, useState } from 'react';
import { Button, Dropdown, Input, Table } from 'semantic-ui-react';
import { objectToCSV } from './helperFunctions';
import aspbackend from '../api/aspbackend';
import SingleFiltersModal from './SingleFiltersModal';

import '../assets/styles/MoodCreatorTableEdit.css';

const MoodCreatorTableEdit = ({
  activeSchedule,
  site,
  sites,
  playlistsASP,
  handleEditable,
  triggerSitesRefresh,
}) => {
  const userToken = localStorage.getItem('token');

  const [saveable, setSaveable] = useState(true);
  const [saving, setSaving] = useState(false);
  const [newPERPlaylists, setNewPERPlaylists] = useState([]);
  const [newEXTPlaylists, setNewEXTPlaylists] = useState([]);
  const [localBannedURIs, setLocalBannedURIs] = useState(
    site?.bannedURIs.join(','),
  );
  const [localScheduleGroup, setLocalScheduleGroup] = useState(
    site?.playlistsGroup,
  );
  const [openAdvancedModal, setOpenAdvancedModal] = useState(false);
  const [filtersModalParams, setFiltersModalParams] = useState({});
  const [refreshingListFactors, setRefreshingListFactors] = useState(false);
  const [listFactors, setListFactors] = useState({});
  const [newActiveSchedule, setNewActiveSchedule] = useState(activeSchedule);

  useEffect(() => {
    var perWeightsSum = newActiveSchedule?.perWeights
      ? calcWeightsSum(newActiveSchedule?.perWeights)
      : null;
    var extWeightsSum = newActiveSchedule?.extWeights
      ? calcWeightsSum(newActiveSchedule?.extWeights)
      : null;
    if (
      (perWeightsSum === null ||
        perWeightsSum === 0 ||
        perWeightsSum === 100) &&
      (extWeightsSum === null || extWeightsSum === 0 || extWeightsSum === 100)
    ) {
      setSaveable(true);
    } else {
      setSaveable(false);
    }
  }, [
    JSON.stringify(newActiveSchedule?.perWeights),
    JSON.stringify(newActiveSchedule?.extWeights),
  ]);

  const handleEditPlaylists = (type, e, data) => {
    type === 'PER'
      ? setNewPERPlaylists(data.value)
      : setNewEXTPlaylists(data.value);
  };

  const dropDownNewPL = (type, playlists) => {
    const playlistOptions = playlists
      .filter((p) =>
        type === 'PER'
          ? p.name.startsWith('PER-') &&
            !newActiveSchedule.perList.includes(p.id)
          : p.name.startsWith('ING-') ||
            (p.name.startsWith('CAS-') &&
              !newActiveSchedule.extList.includes(p.id)),
      )
      .map((p) => {
        return {
          key: p.id,
          text: p.name + ' - ' + p.tracks.total,
          value: p.id,
        };
      });
    return (
      <div style={{}}>
        <Dropdown
          style={{
            margin: '1rem 0',
            display: 'block',
          }}
          placeholder="agregar lista"
          clearable
          selection
          multiple
          options={playlistOptions}
          value={type === 'PER' ? newPERPlaylists : newEXTPlaylists}
          onChange={(e, data) => handleEditPlaylists(type, e, data)}
        />
      </div>
    );
  };

  const handleCalculateListFactors = async () => {
    const perList = activeSchedule.perList;
    const extList = activeSchedule.extList;
    setRefreshingListFactors(true);
    const userToken = localStorage.getItem('token');
    console.log(
      newActiveSchedule?.perWeights,
      newActiveSchedule?.extWeights,
      perList,
      extList,
    );

    const newListFactorsResponse = await aspbackend.post(
      `/site/${site.siteID}/refreshPlaylists?soundtrack=false&scheduleAlias=${newActiveSchedule?.scheduleAlias}&getListFactors=1`,
      {
        perWeights: newActiveSchedule?.perWeights,
        extWeights: newActiveSchedule?.extWeights,
        perList,
        extList,
      },
      { headers: { 'x-access-token': userToken } },
    );
    const newListFactors = newListFactorsResponse.data;
    setListFactors(newListFactors);
    setRefreshingListFactors(false);
  };

  const handleNewFeatures = (type, pid, features) => {
    const newFeatures = features;

    for (const [key, value] of Object.entries(newFeatures)) {
      if (Array.isArray(value) && !value.length) {
        delete newFeatures[key];
      }
      if (key === 'enableExplicit' && value === false) {
        delete newFeatures[key];
      }
    }

    const newFeaturesIsEmpty =
      Object.keys(newFeatures).length === 0 &&
      newFeatures.constructor === Object;

    if (pid) {
      if (newFeaturesIsEmpty) {
        setNewActiveSchedule((current) => {
          const copiedSchedule = { ...current };
          delete copiedSchedule.audioFeatures.listFeatures[pid];
          return copiedSchedule;
        });
      } else {
        setNewActiveSchedule({
          ...newActiveSchedule,
          audioFeatures: {
            ...newActiveSchedule.audioFeatures,
            listFeatures: {
              ...newActiveSchedule.audioFeatures.listFeatures,
              [pid]: newFeatures,
            },
          },
        });
      }
    } else if (type === 'PER') {
      setNewActiveSchedule({
        ...newActiveSchedule,
        audioFeatures: {
          ...newActiveSchedule.audioFeatures,
          perFeatures: newFeatures,
        },
      });
    } else if (type == 'EXT') {
      setNewActiveSchedule({
        ...newActiveSchedule,
        audioFeatures: {
          ...newActiveSchedule.audioFeatures,
          extFeatures: newFeatures,
        },
      });
    }
  };

  const filtersModal = () => {
    return (
      <SingleFiltersModal
        key={filtersModalParams?.type + filtersModalParams?.pid}
        modalParams={filtersModalParams}
        featuresObject={
          filtersModalParams?.pid
            ? newActiveSchedule?.audioFeatures?.listFeatures?.[
                filtersModalParams.pid
              ]
            : filtersModalParams?.type === 'PER'
            ? newActiveSchedule.audioFeatures?.perFeatures
            : newActiveSchedule.audioFeatures?.extFeatures
        }
        openAdvancedModal={openAdvancedModal}
        setOpenAdvancedModal={setOpenAdvancedModal}
        handleNewFeatures={handleNewFeatures}
      />
    );
  };

  const openFiltersModal = (type, pid, pname) => {
    setOpenAdvancedModal(true);
    setFiltersModalParams({ type, pid, pname });
  };

  const filtersButton = (type, pid, pname) => {
    return (
      <Button
        style={{ padding: '0.3rem' }}
        secondary
        size="mini"
        onClick={() => openFiltersModal(type, pid, pname)}>
        modificar
      </Button>
    );
  };

  const renderPlaylists = (type) => {
    const activeList =
      type === 'PER' ? newActiveSchedule.perList : newActiveSchedule.extList;
    const playlists = playlistsASP
      .filter((p) => activeList.includes(p.id))
      .map((p) => {
        return { name: p.name, id: p.id, length: p.tracks.total };
      });
    return (
      <Table.Row>
        <Table.Cell>{`Listas ${type}`}</Table.Cell>
        <Table.Cell>
          <Table className="subTable">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell width={3}>Listas incluidas</Table.HeaderCell>
                <Table.HeaderCell width={5}>
                  Filtros
                  <br />
                  {filtersButton(type)}
                  <span
                    style={{
                      color: 'blue',
                      fontSize: '0.8rem',
                    }}>
                    {type === 'PER'
                      ? newActiveSchedule.audioFeatures?.perFeatures
                        ? objectToCSV(
                            newActiveSchedule.audioFeatures?.perFeatures,
                            ' | ',
                          )
                        : '-- sin filtros --'
                      : newActiveSchedule.audioFeatures?.extFeatures
                      ? objectToCSV(
                          newActiveSchedule.audioFeatures?.extFeatures,
                          ' | ',
                        )
                      : '-- sin filtros --'}
                  </span>
                </Table.HeaderCell>
                <Table.HeaderCell width={1}>
                  Pesos
                  <Button
                    size="mini"
                    onClick={() => handleCalculateListFactors()}
                    // disabled={refreshingListFactors()}
                    loading={refreshingListFactors}
                    color="blue"
                    icon="redo"
                  />
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {playlists.map((p, index) => (
                <Table.Row key={p.id}>
                  <Table.Cell width={3}>
                    {delPlaylistButton(type, p.id)}
                    {p.name}
                    <p style={{ color: 'grey', fontSize: '0.8rem' }}>
                      {p.length} canciones
                    </p>
                  </Table.Cell>
                  <Table.Cell width={5}>
                    {filtersButton(type, p.id, p.name)}
                    {newActiveSchedule.audioFeatures?.listFeatures?.[p.id]
                      ? objectToCSV(
                          newActiveSchedule.audioFeatures?.listFeatures[p.id],
                          ' | ',
                        )
                      : '-- sin filtros --'}
                  </Table.Cell>
                  <Table.Cell width={1}>
                    {type === 'PER' ? (
                      <>
                        {inputText(
                          newActiveSchedule?.perWeights?.[p.id]
                            ? newActiveSchedule.perWeights[p.id]
                            : '',
                          'perWeights',
                          p.id,
                          'integer',
                          true,
                          '50px',
                        )}
                        {listFactors ? (
                          <span style={{ color: 'blue', fontSize: '0.75rem' }}>
                            variedad:{' '}
                            {parseFloat(
                              listFactors.perListFactors?.[p.id],
                            ).toFixed(2)}
                          </span>
                        ) : null}
                      </>
                    ) : (
                      <>
                        {inputText(
                          newActiveSchedule?.extWeights?.[p.id],
                          'extWeights',
                          p.id,
                          'integer',
                          true,
                          '50px',
                        )}
                        {listFactors ? (
                          <span style={{ color: 'blue', fontSize: '0.75rem' }}>
                            variedad:{' '}
                            {parseFloat(
                              listFactors.extListFactors?.[p.id],
                            ).toFixed(2)}
                          </span>
                        ) : null}
                      </>
                    )}
                  </Table.Cell>
                </Table.Row>
              ))}
              <Table.Row>
                <Table.Cell>{dropDownNewPL(type, playlistsASP)}</Table.Cell>
                <Table.Cell colSpan={2}> {addPlaylistButton(type)}</Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        </Table.Cell>
      </Table.Row>
    );
  };

  const calcWeightsSum = (weightsObj) => {
    return Object.keys(weightsObj)?.reduce(
      (acc, key) => acc + weightsObj[key],
      0,
    );
  };

  const handleInputChange = (
    e,
    attribute1,
    attribute2,
    numberType,
    weights,
  ) => {
    if (weights) {
      if (e.target.value >= 100) {
        e.target.value = 100;
      }
    }

    const attributeNewValue = !numberType
      ? e.target.value
      : e.target.value
      ? numberType === 'integer'
        ? parseInt(e.target.value)
        : parseFloat(e.target.value)
      : null;

    if (!attributeNewValue) {
      !attribute2
        ? setNewActiveSchedule((current) => {
            const copiedSchedule = { ...current };
            delete copiedSchedule[attribute1];
            return copiedSchedule;
          })
        : setNewActiveSchedule((current) => {
            const copiedSchedule = { ...current };
            delete copiedSchedule[attribute1][attribute2];
            return copiedSchedule;
          });
    } else {
      !attribute2
        ? setNewActiveSchedule({
            ...newActiveSchedule,
            [attribute1]: attributeNewValue,
          })
        : setNewActiveSchedule({
            ...newActiveSchedule,
            [attribute1]: {
              ...newActiveSchedule[attribute1],
              [attribute2]: attributeNewValue,
            },
          });
    }
  };

  const inputText = (
    value,
    attribute1,
    attribute2,
    numberType,
    weights,
    minWidth = '150px',
  ) => {
    return (
      <Input
        fluid
        style={{ minWidth }}
        value={value}
        onChange={(e) =>
          handleInputChange(e, attribute1, attribute2, numberType, weights)
        }
      />
    );
  };

  const handleAddPlaylists = (type) => {
    if (type === 'PER') {
      setNewActiveSchedule({
        ...newActiveSchedule,
        perList: newActiveSchedule.perList.concat(newPERPlaylists),
      });
      setNewPERPlaylists([]);
    } else {
      setNewActiveSchedule({
        ...newActiveSchedule,
        extList: newActiveSchedule.extList.concat(newEXTPlaylists),
      });
      setNewEXTPlaylists([]);
    }
  };

  const addPlaylistButton = (type) => {
    return (
      <Button
        size={'big'}
        style={{ padding: 0, color: 'green' }}
        circular
        icon="plus circle"
        onClick={() => handleAddPlaylists(type)}
      />
    );
  };

  const handleDelPlaylist = (type, pid) => {
    if (type === 'PER') {
      setNewActiveSchedule({
        ...newActiveSchedule,
        perList: newActiveSchedule.perList.filter((id) => id !== pid),
      });
      if (newActiveSchedule?.perWeights?.[pid]) {
        setNewActiveSchedule((current) => {
          const copiedSchedule = { ...current };
          delete copiedSchedule.perWeights[pid];
          return copiedSchedule;
        });
      }
    } else {
      setNewActiveSchedule({
        ...newActiveSchedule,
        extList: newActiveSchedule.extList.filter((id) => id !== pid),
      });
      if (newActiveSchedule?.extWeights?.[pid]) {
        setNewActiveSchedule((current) => {
          const copiedSchedule = { ...current };
          delete copiedSchedule.extWeights[pid];
          return copiedSchedule;
        });
      }
    }
    if (newActiveSchedule?.audioFeatures?.listFeatures?.[pid]) {
      setNewActiveSchedule((current) => {
        const copiedSchedule = { ...current };
        delete copiedSchedule.audioFeatures.listFeatures[pid];
        return copiedSchedule;
      });
    }
  };

  const delPlaylistButton = (type, pid) => {
    return (
      <Button
        style={{ padding: 0, color: 'red' }}
        circular
        icon="minus circle"
        onClick={() => handleDelPlaylist(type, pid)}
      />
    );
  };

  const mainButtons = () => {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-end',
        }}>
        <div style={{ display: 'flex' }}>
          <Button
            size="tiny"
            disabled={!saveable}
            color="blue"
            onClick={handleSave}
            loading={saving}>
            Grabar
          </Button>
          <Button
            size="tiny"
            color="red"
            loading={saving}
            onClick={() => handleEditable(false)}>
            Cancelar
          </Button>
        </div>
      </div>
    );
  };

  const handleSave = async () => {
    setSaving(true);
    // Apply change to all sites in group
    if (site.businessName) {
      var affectedSites = sites.filter(
        (s) => s.businessName === site.businessName,
      );
    } else {
      affectedSites = [site];
    }
    const newSchedulesArray = site.playlists.map((s) => {
      if (s.scheduleAlias === activeSchedule.scheduleAlias) {
        return newActiveSchedule;
      } else {
        return s;
      }
    });

    for (const affectedSite of affectedSites) {
      await aspbackend.put(
        `/site/${affectedSite.siteID}`,
        {
          playlists: newSchedulesArray,
          bannedURIs: localBannedURIs.split(','),
          playlistsGroup: localScheduleGroup,
        },
        { headers: { 'x-access-token': userToken } },
      );
    }
    await triggerSitesRefresh();

    setSaving(false);
    handleEditable(false);
  };

  return (
    <>
      {filtersModal()}
      {mainButtons()}
      <Table striped fixed celled unstackable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell width={2}>Schedule</Table.HeaderCell>
            <Table.HeaderCell width={8}>
              <div
                style={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  flexDirection: 'row',
                  justifyContent: 'center',
                }}>
                {inputText(newActiveSchedule.scheduleAlias, 'scheduleAlias')}
              </div>
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <Table.Row>
            <Table.Cell>MIX Spotify</Table.Cell>
            <Table.Cell>
              {inputText(newActiveSchedule.spotifyID, 'spotifyID')}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>MIX Soundtrack</Table.Cell>
            <Table.Cell>
              {inputText(newActiveSchedule.soundtrackID, 'soundtrackID')}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Horas</Table.Cell>
            <Table.Cell>
              {inputText(newActiveSchedule.hours, 'hours', null, 'integer')}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Ratio Peruanas</Table.Cell>
            <Table.Cell>
              {inputText(newActiveSchedule.ratio, 'ratio', null, 'float')}
            </Table.Cell>
          </Table.Row>
          {renderPlaylists('PER')}
          {renderPlaylists('EXT')}
          <Table.Row>
            <Table.Cell>Grupo de zonas</Table.Cell>
            <Table.Cell>
              <Input
                fluid
                size="small"
                placeholder="nombre que agrupa zonas"
                value={localScheduleGroup}
                onChange={(e) => setLocalScheduleGroup(e.target.value)}
              />{' '}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Bloqueadas</Table.Cell>
            <Table.Cell>
              <Input
                fluid
                size="small"
                placeholder="uri1,uri2,..."
                value={localBannedURIs}
                onChange={(e) => setLocalBannedURIs(e.target.value)}
              />
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    </>
  );
};

export default MoodCreatorTableEdit;
