import React, { useCallback, useContext, useState, useEffect } from 'react';
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import SpotifyWebApi from 'spotify-web-api-js';
import SpotifyPlayer from 'react-spotify-web-playback';
import axios from 'axios';
import aspbackend from '../api/aspbackend';
import '../assets/styles/Admin.css';
import PlaylistsMenu from './PlaylistsMenu';
import { Context as SpotifyContext } from '../context/SpotifyContext';
import { Context as GeneralContext } from '../context/GeneralContext';

const spotify = new SpotifyWebApi();

const SpotifyWebPlayer = ({ site }) => {
  const navigate = useNavigate();
  const {
    getPlaylistsUser,
    getPlaylistsPER,
    setActivePlaylist,
    state: { playlistsPER, playlistsUser, activePlaylist },
  } = useContext(SpotifyContext);

  const { setUser } = useContext(GeneralContext);

  const [validSpotifyToken, setValidSpotifyToken] = useState('');
  const [loading, setLoading] = useState(true);
  const [playlistOffset, setPlaylistOffset] = useState(0);
  const [trackCounter, setTrackCounter] = useState(0);

  const [searchParams, setSearchParams] = useSearchParams();
  const authEndpoint = 'https://accounts.spotify.com/authorize';
  const tokenEndpoint = 'https://accounts.spotify.com/api/token';
  const redirectUri = `https://aquisuenaperu.com/sitios/${site?.siteID}/admin`;
  // const redirectUri = `http://localhost:3000/sitios/${site?.siteID}/admin`;
  const client_id = site?.spotCI;
  const client_secret = site?.spotCS;
  const scopes = [
    'streaming',
    'user-read-email',
    'user-read-private',
    'user-library-read',
    'user-library-modify',
    'user-read-playback-state',
    'user-modify-playback-state',
    'user-read-recently-played',
    'playlist-modify-public',
    'playlist-modify-private',
    'playlist-read-private',
    'playlist-read-collaborative',
  ];
  const loginUrl = `${authEndpoint}?client_id=${client_id}&redirect_uri=${redirectUri}&scope=${scopes.join(
    '%20',
  )}&response_type=code&show_dialog=true`;

  const fetchToken = async (code) => {
    await axios
      .post(
        tokenEndpoint,
        {
          grant_type: 'authorization_code',
          client_secret,
          client_id,
          redirect_uri: redirectUri,
          code,
        },
        {
          headers: {
            accept: 'application/json',
            'content-type': 'application/x-www-form-urlencoded',
          },
        },
      )
      .then((response) => {
        const _spotifyToken = response.data.access_token;
        const _spotifyRefreshToken = response.data.refresh_token;
        if (_spotifyToken) {
          // console.log('setting new token Fetch', _spotifyToken);
          setValidSpotifyToken(_spotifyToken);
          spotify.setAccessToken(_spotifyToken);
          localStorage.setItem('spotifyToken', _spotifyToken);
          localStorage.setItem('spotifyRefreshToken', _spotifyRefreshToken);
        }
      });
  };

  const refreshToken = async (token) => {
    await axios
      .post(
        tokenEndpoint,
        {
          grant_type: 'refresh_token',
          client_secret,
          client_id,
          redirect_uri: redirectUri,
          refresh_token: token,
        },
        {
          headers: {
            accept: 'application/json',
            'content-type': 'application/x-www-form-urlencoded',
          },
        },
      )
      .then((response) => {
        // console.log('new refreshed token', response.data);
        const _spotifyToken = response.data.access_token;
        if (_spotifyToken) {
          // console.log('setting new token Refresh', _spotifyToken);
          setValidSpotifyToken(_spotifyToken);
          spotify.setAccessToken(_spotifyToken);
          localStorage.setItem('spotifyToken', _spotifyToken);
        }
      })
      .catch((e) => {
        // console.log('refresh token failed', e);
      });
  };
  const getCodeFromUrl = () => {
    if (document.URL.includes('code')) {
      // console.log('URL for code getting is', document.URL);
      return document.URL.split('?')[1].split('=')[1];
    }
    return null;
  };

  const validateWebToken = async (token) => {
    await aspbackend
      .get('/checkToken', { headers: { 'x-access-token': token } })
      .then(async (response) => {
        setUser(response.data);
        const firstSite = response.data.sites[0];
        await aspbackend.get(`site/${firstSite}`).then((response) => {
          navigate(`/sitios/${firstSite}/admin`, {
            state: {
              site: response.data,
            },
          });
        });
      })
      .catch(() => {
        navigate(`/`);
      });
  };

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      validateWebToken(token);
    } else {
      navigate(`/`);
    }
  }, []);

  useEffect(() => {
    const savedSpotifyToken = localStorage.getItem('spotifyToken');
    const savedRefreshSpotifyToken = localStorage.getItem(
      'spotifyRefreshToken',
    );

    if (savedSpotifyToken) {
      // console.log('got saved token, trying with', savedSpotifyToken);
      axios
        .get('https://api.spotify.com/v1/me', {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + savedSpotifyToken,
          },
        })
        .then(() => {
          // console.log('auth successful with existing token');
          setValidSpotifyToken(savedSpotifyToken);
          spotify.setAccessToken(savedSpotifyToken);
        })
        .catch((e) => {
          // console.log('failed with saved token', e);
          // console.log('trying with refresh token');
          if (savedRefreshSpotifyToken) {
            refreshToken(savedRefreshSpotifyToken);
          }
        });
    }
    const _spotifyCode = getCodeFromUrl();
    if (_spotifyCode) {
      // console.log('fetching spotify token with', _spotifyCode);
      fetchToken(_spotifyCode);
    }
    // calculate playlist offset
    const currentURI =
      localStorage.getItem('currentURI') ||
      site?.currentTrack?.uri ||
      site?.currentTrack?.uri;
    const newPlaylistURIs =
      JSON.parse(localStorage.getItem('newPlaylistURIs')) ||
      site?.newPlaylistURIs ||
      site?.newPlaylistURIs;
    if (currentURI && newPlaylistURIs) {
      const newPlaylistOffset =
        newPlaylistURIs.indexOf(currentURI) > 0
          ? newPlaylistURIs.indexOf(currentURI)
          : 0;
      setPlaylistOffset(newPlaylistOffset);
    }
    // finish loading
    setLoading(false);
  }, [client_id, client_secret]);

  // const refreshTokenperiodically = (token) => {
  //   const _intervalID = setInterval(() => {
  //     refreshToken(token);
  //     console.log('refreshing token after 10m');
  //   }, 600000);
  //   clearInterval(_intervalID - 1);
  // };

  useEffect(() => {
    if (validSpotifyToken) {
      getPlaylistsUser();
      getPlaylistsPER();
      const _activePlaylist = JSON.parse(
        localStorage.getItem('activePlaylist'),
      );
      if (_activePlaylist) {
        setActivePlaylist(_activePlaylist);
      } else if (site.existingPlaylist) {
        setActivePlaylist(site.existingPlaylist);
      }
    }
    // delete code if it's there
    if (validSpotifyToken && searchParams.get('code')) {
      searchParams.delete('code');
      setSearchParams(searchParams);
    }
  }, [validSpotifyToken]);

  const handleCallback = useCallback(
    async ({ track, type, ...state }) => {
      if (type === 'track_update') {
        let newTrackCounter = trackCounter + 1;
        setTrackCounter(newTrackCounter);
        await aspbackend.put(
          `/site/${site?.siteID}?updateType=nowPlaying`,
          {
            currentTrack: track,
          },
          { headers: { 'x-access-token': localStorage.getItem('token') } },
        );

        localStorage.setItem('currentURI', track.uri);
        // refresh token if 3 tracks passed
        // console.log(trackCounter);
        if (trackCounter === 3) {
          setTrackCounter(0);
          // refresh Token
          const savedRefreshSpotifyToken = localStorage.getItem(
            'spotifyRefreshToken',
          );
          if (savedRefreshSpotifyToken) {
            refreshToken(savedRefreshSpotifyToken);
          }
        }
      }
      // ensure repeat is always set to repeat the context
      const externalState = await spotify.getMyCurrentPlaybackState();
      if (externalState?.repeat_state !== 'context') {
        // console.log('setting repeat to context');
        spotify.setRepeat('context');
      }
    },
    [trackCounter],
  );

  const handleLoading = (value) => {
    setLoading(value);
  };

  const resetPlaylistOffset = () => {
    setPlaylistOffset(0);
  };

  return (
    <div>
      <p>
        Administración de Reproductor para{' '}
        <strong>{site?.siteDetails?.name}</strong>
      </p>
      {!validSpotifyToken && !loading ? (
        <a href={loginUrl} id="signInButton" className="spotifyButton">
          Conectar Spotify
        </a>
      ) : playlistsPER?.length > 0 ? (
        <>
          <PlaylistsMenu
            playlists={playlistsUser}
            playlistsPER={playlistsPER}
            spotify={spotify}
            site={site}
            resetPlaylistOffset={resetPlaylistOffset}
            handleLoading={handleLoading}
            loading={loading}
            userToken={localStorage.getItem('token')}
          />
          {!loading && activePlaylist ? (
            <>
              <h5>
                Lista activa: {activePlaylist.name}
                <span className="mixedPlaylistNames">
                  {' '}
                  {site.playlistPERName
                    ? `(mezcla de ${site.playlistPERName}`
                    : null}
                  {site.playlistEXTName ? ` & ${site.playlistEXTName}` : null}
                  {site.playlistPERName ? ')' : null}
                </span>
              </h5>

              <SpotifyPlayer
                callback={handleCallback}
                persistDeviceSelection
                syncExternalDevice
                offset={playlistOffset}
                token={validSpotifyToken}
                uris={[activePlaylist.uri]}
              />
            </>
          ) : null}
        </>
      ) : null}
    </div>
  );
};

export default SpotifyWebPlayer;
