import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Button, Form, Input, TextArea } from 'semantic-ui-react';
import Datetime from 'react-datetime';
import Dots from 'react-activity/dist/Dots';
import 'react-activity/dist/Dots.css';
import 'react-datetime/css/react-datetime.css';
import people from '../assets/images/people.png';
import aspbackend from '../api/aspbackend';
import '../assets/styles/Artist.css';
import tiktokLogo from '../assets/images/tiktok.svg';

const Artist = () => {
  const location = useLocation();
  const artistName = location?.state?.artistName;

  const [isUpdating, setIsUpdating] = useState(false);

  const [artist, setArtist] = useState(null);
  const [localImage, setLocalImage] = useState(null);
  const [remoteImage, setRemoteImage] = useState(null);

  const [bio, setBio] = useState(null);
  const [eventName, setEventName] = useState(null);
  const [eventLocation, setEventLocation] = useState(null);
  const [eventURL, setEventURL] = useState(null);
  const [eventDate, setEventDate] = useState(null);

  const [facebookURL, setFacebookURL] = useState(null);
  const [instagramURL, setInstagramURL] = useState(null);
  const [spotifyURL, setSpotifyURL] = useState(null);
  const [tiktokURL, setTiktokURL] = useState(null);
  const [twitterURL, setTwitterURL] = useState(null);
  const [youtubeURL, setYoutubeURL] = useState(null);

  useEffect(() => {
    aspbackend
      .get(`artist?name=${encodeURIComponent(artistName)}`)
      .then((response) => {
        setBio(response.data.bio);
        setRemoteImage(response.data.image);
        setEventName(response.data.upcomingEvent?.name);
        setEventLocation(response.data.upcomingEvent?.location);
        setEventURL(response.data.upcomingEvent?.url);
        setEventDate(Date.parse(response.data.upcomingEvent?.date));
        setFacebookURL(response.data.socialNetworks?.facebookURL);
        setInstagramURL(response.data.socialNetworks?.instagramURL);
        setSpotifyURL(response.data.socialNetworks?.spotifyURL);
        setTiktokURL(response.data.socialNetworks?.tiktokURL);
        setTwitterURL(response.data.socialNetworks?.twitterURL);
        setYoutubeURL(response.data.socialNetworks?.youtubeURL);
        setArtist(response.data);
      });
  }, []);

  const onDateChange = (data) => {
    setEventDate(data);
  };

  const updateArtist = () => {
    setIsUpdating(true);
    const formData = new FormData();
    const upcomingEvent = {
      name: eventName,
      location: eventLocation,
      url: eventURL,
      date: eventDate,
    };
    const socialNetworks = {
      facebookURL,
      twitterURL,
      instagramURL,
      tiktokURL,
      spotifyURL,
      youtubeURL,
    };
    if (localImage) {
      formData.append('image', localImage);
    }
    formData.append('bio', bio);
    formData.append('upcomingEvent', JSON.stringify(upcomingEvent));
    formData.append('socialNetworks', JSON.stringify(socialNetworks));
    aspbackend
      .put(`/artist?name=${encodeURIComponent(artist.name)}`, formData, {
        headers: {
          'x-access-token': localStorage.getItem('token'),
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(() => setIsUpdating(false));
  };

  const tiktokIcon = (
    <i className="icon tiktokIcon">
      <img
        width={16}
        height={16}
        style={{ textAlign: 'center', verticalAlign: 'center', margin: 'auto' }}
        src={tiktokLogo}
      />
    </i>
  );

  return artist ? (
    <div className="artistDiv">
      <h4>Espacio de {artist.name}</h4>
      <Form>
        <Form.Field>
          <label>Bio / Reseña</label>
          <TextArea
            maxLength="300"
            style={{ minHeight: 100 }}
            placeholder="Biografía o reseña (300 caracteres)"
            value={bio}
            onChange={(e) => {
              setBio(e.target.value);
            }}
          />
        </Form.Field>
        <Form.Field>
          <label>Imagen principal (cuadrada)</label>
          {localImage || remoteImage ? (
            <div>
              <img
                alt="imagen del músico (debe ser cuadrada, con extensión PNG, JPG o JPEG)"
                width={'250px'}
                src={localImage ? URL.createObjectURL(localImage) : remoteImage}
              />
              <br />
              <Button
                color="red"
                size="mini"
                onClick={() => {
                  setLocalImage(null);
                  setRemoteImage(null);
                }}>
                Remover
              </Button>
            </div>
          ) : (
            <img src={people} alt="sin imagen" width={'250px'} />
          )}
          <div className="mb-1">
            <div className="">
              <input
                style={{ marginTop: 5 }}
                type="file"
                onChange={(e) => {
                  setLocalImage(e.target.files[0]);
                }}
              />
            </div>
          </div>
        </Form.Field>
        <Form.Field>
          <label>Redes sociales</label>
          <Input
            icon="facebook"
            iconPosition="left"
            style={{ marginTop: 5 }}
            maxLength="100"
            placeholder="Facebook link"
            value={facebookURL}
            onChange={(e) => setFacebookURL(e.target.value)}
          />
          <Input
            icon="instagram"
            iconPosition="left"
            style={{ marginTop: 5 }}
            maxLength="100"
            placeholder="Instagram link"
            value={instagramURL}
            onChange={(e) => setInstagramURL(e.target.value)}
          />
          <Input
            icon="spotify"
            iconPosition="left"
            style={{ marginTop: 5 }}
            maxLength="100"
            placeholder="Spotify link"
            value={spotifyURL}
            onChange={(e) => setSpotifyURL(e.target.value)}
          />
          <Input
            icon={tiktokIcon}
            iconPosition="left"
            style={{ marginTop: 5 }}
            maxLength="100"
            placeholder="TikTok link."
            value={tiktokURL}
            onChange={(e) => setTiktokURL(e.target.value)}
          />
          <Input
            icon="twitter"
            iconPosition="left"
            style={{ marginTop: 5 }}
            maxLength="100"
            placeholder="Twitter link"
            value={twitterURL}
            onChange={(e) => setTwitterURL(e.target.value)}
          />
          <Input
            icon="youtube"
            iconPosition="left"
            style={{ marginTop: 5 }}
            maxLength="100"
            placeholder="Youtube link"
            value={youtubeURL}
            onChange={(e) => setYoutubeURL(e.target.value)}
          />
        </Form.Field>
        <Form.Field>
          <label>Próximo evento</label>
          <Input
            style={{ marginTop: 5 }}
            maxLength="100"
            placeholder="Nombre del evento (100 caracteres)"
            value={eventName}
            onChange={(e) => setEventName(e.target.value)}
          />
          <Input
            style={{ marginTop: 5 }}
            maxLength="100"
            placeholder="Nombre del local (100 caracteres)"
            value={eventLocation}
            onChange={(e) => setEventLocation(e.target.value)}
          />
          <Input
            style={{ marginTop: 5, marginBottom: 5 }}
            maxLength="250"
            placeholder="Enlace al evento o compra de entradas"
            value={eventURL}
            onChange={(e) => setEventURL(e.target.value)}
          />
          <Datetime
            onChange={onDateChange}
            value={eventDate}
            inputProps={{ placeholder: 'Fecha y hora' }}
          />
        </Form.Field>
        <Button
          loading={isUpdating}
          type="submit"
          color="green"
          size="large"
          onClick={updateArtist}>
          Grabar
        </Button>
      </Form>
    </div>
  ) : (
    <div style={{ textAlign: 'center' }}>
      <Dots />
    </div>
  );
};

export default Artist;
