export const objectToCSV = (object, delimiter) => {
  let string = [];
  for (const key in object) {
    if (key === 'yearRanges') {
      string.push(`${key}: ${object[key].map((e) => e[0] + 's')}`);
    } else if (key === 'enableExplicit') {
      string.push(`${key}: ${object[key].toString()}`);
    } else {
      string.push(`${key}: ${object[key][0]}-${object[key][1]}`);
    }
  }
  return string.join(delimiter);
};

export const formatDate = (date) => {
  const newDate = new Date(date);
  return newDate.toLocaleString('en-GB', { timeZone: 'America/Lima' });
};
