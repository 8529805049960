import React from 'react';
import ReactCountryFlag from 'react-country-flag';
import { Image, List } from 'semantic-ui-react';
import '../assets/styles/History.css';

const HistorySYB = ({ history }) => {
  const renderItems = () => {
    return history.map((track) => {
      return track.artists?.length ? (
        <List.Item key={track.title + Math.random()}>
          <Image
            src={track.album?.display?.image?.sizes?.thumbnail}
            className="listImg"
          />
          <List.Content className="listText">
            <List.Header>
              {track.artists?.length === 1
                ? track.artists[0].name
                : track.artists
                    .filter((artist) => !artist.name.includes(' & '))
                    .map(
                      (artist, index, artists) =>
                        artist.name +
                        (artists.length > 1 && index + 1 !== artists.length
                          ? ', '
                          : ''),
                    )}
              {track?.isLocal ? <ReactCountryFlag countryCode="PE" /> : null}
            </List.Header>
            <List.Description>
              <p>{track.title}</p>
            </List.Description>
          </List.Content>
        </List.Item>
      ) : null;
    });
  };

  return (
    <div className="historyDiv left">
      <List divided relaxed>
        {renderItems()}
      </List>
    </div>
  );
};

export default HistorySYB;
