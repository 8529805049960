import React from 'react';
import '../assets/styles/UpcomingEvent.css';

const UpcomingEvent = ({ event }) => {
  const date = new Date(event.date);
  const day = ('0' + date.getDate()).slice(-2);
  const monthNames = [
    'ENE',
    'FEB',
    'MAR',
    'ABR',
    'MAY',
    'JUN',
    'JUL',
    'AGO',
    'SET',
    'OCT',
    'NOV',
    'DIC',
  ];
  const month = monthNames[date.getMonth()];

  return (
    <div className="eventDiv">
      <a href={event.url} target="_blank" style={{ display: 'flex' }}>
        <div className="dateDiv">
          <div className="dayDiv">{day}</div>
          <div className="monthDiv">{month}</div>
        </div>
        <div className="eventTextDiv">
          <div className="eventNameDiv">{event.name}</div>
          <div className="eventLocationDiv">{event.location}</div>
        </div>
      </a>
    </div>
  );
};

export default UpcomingEvent;
