import { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Dropdown } from 'semantic-ui-react';
import { Context as GeneralContext } from '../context/GeneralContext';
import { Context as SpotifyContext } from '../context/SpotifyContext';
import MoodCreatorTable from './MoodCreatorTable';
import MoodCreatorTableEdit from './MoodCreatorTableEdit';

const MoodCreator = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [editable, setEditable] = useState(false);
  const [loading, setLoading] = useState(false);
  const [editableIndex, setEditableIndex] = useState(0);
  const [refreshingPlaylist, setRefreshingPlaylist] = useState(false);
  const [refreshingScheduleVariety, setRefreshingScheduleVariety] =
    useState(false);

  const {
    getAdminInfo,
    getSites,
    getSYBPlaylists,
    setSite,
    setUser,
    state: { adminInfo, playlistsSYB, site, sites, tokenIsValid, user },
  } = useContext(GeneralContext);

  const {
    getPlaylistsASP,
    state: { playlistsASP },
  } = useContext(SpotifyContext);

  const triggerSitesRefresh = async () => {
    setLoading(true);
    const getSitesOnce = async () => {
      await getSites();
    };
    await getSitesOnce();
    setLoading(false);
  };

  useEffect(() => {
    if (!tokenIsValid) {
      setUser(null);
      setSite(null);
      localStorage.clear();
      navigate(`/`);
    }
    setLoading(true);
    const getSYBPlaylistsOnce = async () => {
      await getSYBPlaylists();
    };
    triggerSitesRefresh();
    getSYBPlaylistsOnce();
    setLoading(false);
  }, []);

  useEffect(() => {
    setEditableIndex(0);
  }, [site?.siteID]);

  useEffect(() => {
    const getAdminInfoOnce = async (token) => {
      await getAdminInfo(token);
    };
    if (user) {
      getAdminInfoOnce(user?.token);
    }
  }, [user]);

  useEffect(() => {
    const activeSiteID = /[^/]*$/.exec(location.pathname)[0];
    if (sites?.length && activeSiteID) {
      handleSiteState(null, { value: activeSiteID });
    } else if (sites?.length) {
      setSite(sites[0]);
    }
  }, [sites]);

  useEffect(() => {
    setLoading(true);
    if (adminInfo && !playlistsASP.length) {
      const getPlaylistsASPOnce = async (sc, ss, srt) => {
        await getPlaylistsASP(sc, ss, srt);
      };
      getPlaylistsASPOnce(adminInfo.sc, adminInfo.ss, adminInfo.srt);
    }
    setLoading(false);
  }, [adminInfo]);

  const handleSiteState = async (e, { value }) => {
    const newSite = sites.filter((s) => s.siteID === value)[0];
    navigate(`/admin/moodcreator/${newSite.siteID}`, { shallow: true });
    setSite(newSite);
  };

  const handleEditable = async () => {
    setEditable(false);
  };

  const mainButtons = () => {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-end',
        }}>
        {!editable ? (
          <>
            <Button
              size="tiny"
              color="blue"
              disabled={refreshingPlaylist}
              onClick={() => navigate(`/admin/`)}>
              Volver
            </Button>
            <Button
              size="tiny"
              color="green"
              disabled={refreshingPlaylist || refreshingScheduleVariety}
              onClick={() => setEditable(true)}>
              Editar
            </Button>
          </>
        ) : null}
      </div>
    );
  };

  const siteSelector = () => {
    const siteOptions = sites
      .filter((site) => site.sourceType === 'soundtrack')
      .map((site) => {
        return {
          text: site.siteDetails.name,
          value: site.siteID,
        };
      });
    return (
      <Dropdown
        placeholder="Sitio"
        clearable
        selection
        disabled={editable || refreshingPlaylist || refreshingScheduleVariety}
        options={siteOptions}
        value={site.siteID}
        onChange={handleSiteState}
        style={{
          margin: '1rem 0',
          display: 'block',
        }}
      />
    );
  };

  const handleIndexChange = (index) => {
    setEditableIndex(index);
  };

  return (
    <div className="adminDiv">
      {site && sites.length ? (
        <>
          {siteSelector()}
          {mainButtons()}
          {!editable ? (
            <MoodCreatorTable
              site={site}
              playlistsASP={playlistsASP}
              playlistsSYB={playlistsSYB}
              handleIndexChange={handleIndexChange}
              editableIndex={editableIndex}
              triggerSitesRefresh={triggerSitesRefresh}
              loading={loading}
              refreshingPlaylist={refreshingPlaylist}
              setRefreshingPlaylist={setRefreshingPlaylist}
              refreshingScheduleVariety={refreshingScheduleVariety}
              setRefreshingScheduleVariety={setRefreshingScheduleVariety}
            />
          ) : (
            <MoodCreatorTableEdit
              site={site}
              sites={sites}
              playlistsASP={playlistsASP}
              activeSchedule={site.playlists[editableIndex]}
              handleEditable={handleEditable}
              triggerSitesRefresh={triggerSitesRefresh}
            />
          )}
        </>
      ) : null}
    </div>
  );
};

export default MoodCreator;
