import React from 'react';
import '../assets/styles/Footer.css';
import SocialIconsASP from './SocialNetworksASP';

const Footer = ({ site }) => {
  return (
    <div className="footerDiv">
      <p className="footerText">
        ¿Tienes un local y quieres difundir música peruana?
        <br />
        ¿Eres artista peruano y quieres sonar en muchos locales?
        <br />
        <strong>¡Contáctanos!</strong>
      </p>
      <SocialIconsASP />
    </div>
  );
};

export default Footer;
