import createDataContext from './createDataContext';
import SpotifyWebApi from 'spotify-web-api-js';
import axios from 'axios';

const spotify = new SpotifyWebApi();
const spotifyTokenEndpoint = axios.create({
  baseURL: 'https://accounts.spotify.com/api/',
  headers: {
    'Content-type': 'application/json',
  },
});

const spotifyAPIEndpoint = axios.create({
  baseURL: 'https://api.spotify.com/v1/',
  headers: {
    'Content-type': 'application/json',
  },
});

const userReducer = (state, action) => {
  switch (action.type) {
    case 'set_playlists_user':
      return { ...state, playlistsUser: action.payload };
    case 'set_playlists_per':
      return { ...state, playlistsPER: action.payload };
    case 'set_playlists_asp':
      return { ...state, playlistsASP: action.payload };
    case 'set_active_playlist':
      return { ...state, activePlaylist: action.payload };
    default:
      return state;
  }
};

const setActivePlaylist = (dispatch) => async (playlistURI) => {
  dispatch({ type: 'set_active_playlist', payload: playlistURI });
};

const getPlaylistsUser = (dispatch) => async () => {
  const response = await spotify.getUserPlaylists({ limit: 50 });
  dispatch({ type: 'set_playlists_user', payload: response.items });
};

const getPlaylistsASP = (dispatch) => async (sc, ss, srt) => {
  // GET playlists from ASP Profile
  const spotifyTokenResponse = await spotifyTokenEndpoint.post(
    '/token',
    {
      grant_type: 'refresh_token',
      client_id: sc,
      client_secret: ss,
      refresh_token: srt,
    },
    {
      headers: {
        accept: 'application/json',
        'content-type': 'application/x-www-form-urlencoded',
      },
    },
  );
  const spotifyToken = spotifyTokenResponse.data.access_token;
  spotify.setAccessToken(spotifyToken);
  let batch_length = 50;
  let offset = 0;
  let finalPlaylistList = [];
  let existing_playlists = [];
  while (batch_length !== 0) {
    let apiConfig = {
      headers: {
        Authorization: 'Bearer ' + spotifyToken,
      },
      params: {
        limit: batch_length,
        offset,
      },
    };
    const playlistBatch = await spotifyAPIEndpoint.get(
      `users/31mweab3crgbtkz33ilo36sgdrri/playlists`,
      apiConfig,
    );

    existing_playlists = playlistBatch.data.items;
    batch_length = existing_playlists.length;
    if (batch_length > 0) {
      finalPlaylistList.push(...existing_playlists);
    }
    offset += batch_length;
  }
  const orderedList = finalPlaylistList.sort((a, b) =>
    a.name > b.name ? 1 : b.name > a.name ? -1 : 0,
  );
  dispatch({ type: 'set_playlists_asp', payload: orderedList });
};

const getPlaylistsPER = (dispatch) => async () => {
  // GET playlists from ASP Profile - Legacy (depends on user's token)
  const response = await spotify.getUserPlaylists(
    '31mweab3crgbtkz33ilo36sgdrri',
    { limit: 50 },
  );
  dispatch({ type: 'set_playlists_per', payload: response.items });
};

export const { Context, Provider } = createDataContext(
  userReducer,
  { getPlaylistsUser, getPlaylistsASP, getPlaylistsPER, setActivePlaylist },
  {
    playlistsASP: [],
    playlistsPER: [],
    playlistsUser: [],
    activePlaylist: null,
  },
);
