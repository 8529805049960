import React from 'react';

const Banner = ({ image, url }) => {
  return (
    <a
      style={{ marginTop: '1rem' }}
      href={url}
      target="_blank"
      rel="noreferrer">
      <img style={{ width: '100%', height: '100%' }} src={image} alt="banner" />
    </a>
  );
};

export default Banner;
