import React from 'react';
import { Button, Popup } from 'semantic-ui-react';
import '../assets/styles/GlobalSitesView.css';

const GlobalSitesView = ({ sites }) => {
  const filteredSites = sites?.filter(
    (s) => s.siteID !== '0' && s.sourceType === 'soundtrack',
  );

  const renderLeds = (sites) => {
    const orderedSites = filteredSites?.sort(
      (a, b) => parseInt(a.siteID) - parseInt(b.siteID),
    );
    return orderedSites?.map((site) => {
      return (
        <Popup
          on="click"
          key={site.siteID}
          content={
            <a
              href={`https://business.soundtrackyourbrand.com/accounts/${site.soundtrackAccount}/zones/${site.soundtrackZone}`}
              target="_blank"
              rel="noopener noreferrer">
              {site.siteDetails.name}
            </a>
          }
          trigger={
            <Button
              // onClick={() =>
              //   window.open(
              //     `https://business.soundtrackyourbrand.com/accounts/${site.soundtrackAccount}/zones/${site.soundtrackZone}`,
              //     '_blank',
              //     'noopener,noreferrer',
              //   )
              // }
              style={{
                backgroundColor:
                  site.playerStatus === 'playing'
                    ? '#21ba45'
                    : site.playerStatus === 'paused'
                    ? '#FF8C00'
                    : site.playerStatus === 'idle'
                    ? '#ADFF2F'
                    : '#db2828',
                color:
                  site.playerStatus === 'playing' ||
                  site.playerStatus === 'idle'
                    ? 'black'
                    : 'white',
              }}
              className="buttonStyle">
              {site.siteID}
            </Button>
          }
        />
      );
    });
  };

  return (
    <>
      <h4>Sitios registrados: {filteredSites?.length}</h4>
      <div className="globalSitesDiv">
        <Button className="buttonStyle" style={{ backgroundColor: '#21ba45' }}>
          play
        </Button>
        <Button
          className="buttonStyle"
          style={{ backgroundColor: '#FF8C00', color: 'white' }}>
          pause
        </Button>
        <Button className="buttonStyle" style={{ backgroundColor: '#ADFF2F' }}>
          idle
        </Button>
        <Button
          className="buttonStyle"
          style={{ backgroundColor: '#db2828', color: 'white' }}>
          off
        </Button>
        <br />
        <br />
        {renderLeds(sites)}
      </div>
    </>
  );
};

export default GlobalSitesView;
