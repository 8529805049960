import React, { useContext, useEffect } from 'react';
import { Routes, Route, Link, useNavigate } from 'react-router-dom';
import Admin from './Admin';
import AdminNew from './AdminNew';
import { Button, Container } from 'semantic-ui-react';
import logo from '../assets/images/logoasp.png';
import NowPlaying from './NowPlaying';
import Artist from './Artist';
import Login from './Login';
import { Context as GeneralContext } from '../context/GeneralContext';
import MoodCreator from './MoodCreator';

const App = () => {
  const navigate = useNavigate();
  const {
    setUser,
    setSite,
    state: { site, tokenIsValid, user },
  } = useContext(GeneralContext);

  const logout = () => {
    setUser(null);
    setSite(null);
    localStorage.clear();
    navigate(`/`);
  };

  useEffect(() => {
    const localUser = JSON.parse(localStorage.getItem('user'));
    if (!user && localUser) {
      setUser(localUser);
    }
  }, []);

  return (
    <Container text>
      {user?.username && tokenIsValid ? (
        <div className="topDiv">
          <div className="welcomeDiv">Bienvenid@ {user.username} !</div>
          <div className="logoutButtonDiv">
            <Button
              onClick={logout}
              className="logoutButton"
              size="mini"
              color="black">
              Cerrar sesión
            </Button>
          </div>
        </div>
      ) : null}
      <div className="logoDiv">
        <div className="imgWrapper imgWrapperASP">
          <a href="https://instagram.com/aquisuenaperu" target="_blank">
            <img src={logo} />
          </a>
        </div>
        {site?.business?.logo ? (
          <div className="imgWrapper">
            <a href={site?.business?.mainURL} target="_blank">
              <img src={site?.business?.logo} />
            </a>
          </div>
        ) : null}
      </div>
      <Routes>
        {/* <Route path="/" element={<Layout />}> */}
        <Route path="/">
          <Route path="/" element={<Login />} />
          <Route path="/sitios/:siteID/" element={<NowPlaying />} />
          <Route path="/sitios/:siteID/admin" element={<Admin />} />
          <Route path="/admin" element={<AdminNew />} />
          <Route path="/admin/moodcreator/:siteID" element={<MoodCreator />} />
          <Route path="/artist/:artistName/admin" element={<Artist />} />

          {/* Using path="*"" means "match anything", so this route
                acts like a catch-all for URLs that we don't have explicit
                routes for. */}
          <Route path="*" element={<NoMatch />} />
        </Route>
      </Routes>
    </Container>
  );
};

// const Layout = () => {
//   return (
//     <div>
//       {/* A "layout route" is a good place to put markup you want to
//           share across all the pages on your site, like navigation. */}
//       <nav>
//         <ul>
//           <li>
//             <Link to="/:siteID/">Ahora suena</Link>
//           </li>
//           <li>
//             <Link to="/:siteID/admin">Admin</Link>
//           </li>
//         </ul>
//       </nav>

//       <hr />

//       {/* An <Outlet> renders whatever child route is currently active,
//           so you can think about this <Outlet> as a placeholder for
//           the child routes we defined above. */}
//       <Outlet />
//     </div>
//   );
// };

const NoMatch = () => {
  return (
    <div>
      <h2>Nada que ver aquí...</h2>
      <p>
        <Link to="/">Ir a la página principal</Link>
      </p>
    </div>
  );
};

export default App;
