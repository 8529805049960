import React from 'react';
import { IconContext } from 'react-icons';
import {
  FaEnvelope,
  FaFacebook,
  FaInstagram,
  FaTiktok,
  FaTwitter,
  FaSpotify,
} from 'react-icons/fa';
import '../assets/styles/SocialIcons.css';

const SocialIconsASP = ({ currentArtist }) => {
  return (
    <div className="socialIcons">
      <div>
        <IconContext.Provider
          value={{
            color: '#6320EE',
            size: '2rem',
          }}>
          <a
            href="https://facebook.com/aquisuenaperu"
            target="_blank"
            rel="noreferrer">
            <FaFacebook />
          </a>
        </IconContext.Provider>
      </div>
      <div>
        <IconContext.Provider
          value={{
            color: '#6320EE',
            size: '2rem',
          }}>
          <a
            href="https://instagram.com/aquisuenaperu"
            target="_blank"
            rel="noreferrer">
            <FaInstagram />
          </a>
        </IconContext.Provider>
      </div>
      {/* <div>
        <IconContext.Provider
          value={{
            color: '#6320EE',
            size: '2rem',
          }}>
          <a
            href="https://open.spotify.com/user/31mweab3crgbtkz33ilo36sgdrri?si=1ab93cd6a31a4fde"
            target="_blank"
            rel="noreferrer">
            <FaSpotify />
          </a>
        </IconContext.Provider>
      </div> */}
      <div>
        <IconContext.Provider
          value={{
            color: '#6320EE',
            size: '2rem',
          }}>
          <a
            href="https://tiktok.com/@aquisuenaperu"
            target="_blank"
            rel="noreferrer">
            <FaTiktok />
          </a>
        </IconContext.Provider>
      </div>
      <div>
        <IconContext.Provider
          value={{
            color: '#6320EE',
            size: '2rem',
          }}>
          <a
            href="https://twitter.com/aquisuenaperu"
            target="_blank"
            rel="noreferrer">
            <FaTwitter />
          </a>
        </IconContext.Provider>
      </div>
      <div>
        <IconContext.Provider
          value={{
            color: '#6320EE',
            size: '2rem',
          }}>
          <a
            href="mailto:contacto@aquisuenaperu.com"
            target="_blank"
            rel="noreferrer">
            <FaEnvelope />
          </a>
        </IconContext.Provider>
      </div>
    </div>
  );
};

export default SocialIconsASP;
