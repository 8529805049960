import { useContext, useEffect, useState } from 'react';
import { Button, Loader, Table } from 'semantic-ui-react';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import { formatDate, objectToCSV } from './helperFunctions';
import { Context as GeneralContext } from '../context/GeneralContext';
import aspbackend from '../api/aspbackend';
import '../assets/styles/MoodCreatorTable.css';

const MoodCreatorTable = ({
  site,
  playlistsASP,
  playlistsSYB,
  handleIndexChange,
  editableIndex,
  triggerSitesRefresh,
  loading,
  refreshingPlaylist,
  setRefreshingPlaylist,
  refreshingScheduleVariety,
  setRefreshingScheduleVariety,
}) => {
  const {
    getTracks,
    state: { tracks },
  } = useContext(GeneralContext);
  const [scheduleIndex, setScheduleIndex] = useState(editableIndex);
  const [scheduleVariety, setScheduleVariety] = useState(
    site?.playlists[editableIndex]?.scheduleRatios,
  );

  useEffect(() => {
    setScheduleIndex(editableIndex);
    if (!tracks?.length) {
      getTracks();
    }
  }, [editableIndex]);

  const scheduleNavigator = (direction) => {
    let newActiveIndex = 0;
    if (direction === 'right') {
      if (scheduleIndex === site?.playlists?.length - 1) {
        newActiveIndex = 0;
      } else {
        newActiveIndex = scheduleIndex + 1;
      }
    } else {
      if (scheduleIndex === 0) {
        newActiveIndex = site?.playlists.length - 1;
      } else {
        newActiveIndex = scheduleIndex - 1;
      }
    }
    setScheduleIndex(newActiveIndex);
    handleIndexChange(newActiveIndex);
  };

  const renderPlaylists = (type) => {
    const playlistNames =
      type === 'PER' ? includedPERPlaylists : includedEXTPlaylists;
    return (
      <Table.Row rowSpan={playlistNames.length + 1}>
        <Table.Cell>{`Listas ${type}`}</Table.Cell>
        <Table.Cell>
          <Table className={'subTable'}>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell width={3}>Listas incluidas</Table.HeaderCell>
                <Table.HeaderCell width={5}>
                  Filtros
                  <br />
                  <span
                    style={{
                      color: 'blue',
                      fontSize: '0.8rem',
                    }}>
                    {type === 'PER'
                      ? activeSchedule.audioFeatures?.perFeatures
                        ? objectToCSV(
                            activeSchedule.audioFeatures?.perFeatures,
                            ' | ',
                          )
                        : '-- sin filtros --'
                      : activeSchedule.audioFeatures?.extFeatures
                      ? objectToCSV(
                          activeSchedule.audioFeatures?.extFeatures,
                          ' | ',
                        )
                      : '-- sin filtros --'}
                  </span>
                </Table.HeaderCell>
                <Table.HeaderCell width={1}>Pesos</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {playlistNames.map((p, index) => (
                <Table.Row key={p.id}>
                  <Table.Cell width={3}>
                    {p.name}
                    <p style={{ color: 'grey', fontSize: '0.8rem' }}>
                      {p.length} canciones
                    </p>
                  </Table.Cell>
                  {activeSchedule.audioFeatures?.listFeatures?.[p.id] ? (
                    <Table.Cell width={5} style={{ color: 'blue' }}>
                      {objectToCSV(
                        activeSchedule.audioFeatures?.listFeatures[p.id],
                        ' | ',
                      )}
                    </Table.Cell>
                  ) : (
                    <Table.Cell width={5}>-- sin filtros --</Table.Cell>
                  )}
                  <Table.Cell width={1}>
                    {type === 'PER'
                      ? activeSchedule.perWeights?.[p.id]
                        ? `${activeSchedule.perWeights[p.id]} %`
                        : '-- sin % --'
                      : activeSchedule.extWeights?.[p.id]
                      ? `${activeSchedule.extWeights[p.id]} %`
                      : '-- sin % --'}
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </Table.Cell>
      </Table.Row>
    );
  };

  const renderVariety = () => {
    const perVariety = activeSchedule.scheduleRatios?.perResult?.toFixed(2);
    const extVariety = activeSchedule.scheduleRatios?.extResult?.toFixed(2);
    const calcDate = activeSchedule.scheduleRatios?.calcDate
      ? formatDate(activeSchedule.scheduleRatios?.calcDate)
      : null;

    return (
      <>
        <Button
          disabled={refreshingPlaylist}
          onClick={() =>
            handleCalculateScheduleVariety(activeSchedule.scheduleAlias)
          }
          loading={refreshingScheduleVariety}
          color="black"
          size="tiny"
          circular
          icon="redo"
        />
        <span
          style={{
            color: perVariety < 3 ? 'red' : 'green',
          }}>
          PER: {perVariety}
          {' / '}
        </span>
        <span
          style={{
            color: extVariety < 3 ? 'red' : 'green',
          }}>
          EXT: {extVariety}
        </span>{' '}
        <span>(actualizado: {calcDate})</span>
      </>
    );
  };

  const activeSchedule = site?.playlists[scheduleIndex];
  const spotifyPlaylist = playlistsASP.filter(
    (p) => p.id === activeSchedule?.spotifyID,
  )[0];
  const soundtrackPlaylist = playlistsSYB?.filter(
    (p) => p.node.id === activeSchedule?.soundtrackID,
  )[0]?.node;
  const includedPERPlaylists = playlistsASP
    .filter((p) => activeSchedule.perList.includes(p.id))
    .map((p) => {
      return { name: p.name, id: p.id, length: p.tracks.total };
    });
  const includedEXTPlaylists = playlistsASP
    .filter((p) => activeSchedule.extList.includes(p.id))
    .map((p) => {
      return { name: p.name, id: p.id, length: p.tracks.total };
    });

  const handleCalculateScheduleVariety = async (scheduleAlias, index) => {
    setRefreshingScheduleVariety(true);
    const userToken = localStorage.getItem('token');
    const newVarietyData = await aspbackend.post(
      `/site/${site.siteID}/getScheduleRatios?scheduleAlias=${scheduleAlias}`,
      {},
      { headers: { 'x-access-token': userToken } },
    );
    const newVariety = newVarietyData.data;
    await triggerSitesRefresh();
    setScheduleVariety(newVariety);
    setRefreshingScheduleVariety(false);
  };

  const handleRefreshPL = async (soundtrack, getUniverse) => {
    setRefreshingPlaylist(true);
    const userToken = localStorage.getItem('token');
    await aspbackend.post(
      `/site/${
        site.siteID
      }/refreshPlaylists?soundtrack=${soundtrack}&scheduleAlias=${
        activeSchedule.scheduleAlias
      }${getUniverse === 1 ? '&getUniverse=1' : '&getUniverse=0'}`,
      {},
      { headers: { 'x-access-token': userToken } },
    );
    setRefreshingPlaylist(false);
  };

  const renderBanned = (includedPlaylists) => {
    const includedPlaylistNames = includedPlaylists.map((p) => p.name);
    const unidentifiedElements = [];
    const identifiedBannedTracks = [];
    for (const uri of site.bannedURIs) {
      if (tracks?.length && uri.includes('track')) {
        const matchedElements = tracks.filter((t) => uri.includes(t.id));
        if (matchedElements.length) {
          const matchedObjects = matchedElements.map((e) => {
            const matchedObject = {
              uri: e.id,
              text: `${e.artists} - ${e.title}`,
              playlist: e.playlist,
            };
            return matchedObject;
          });
          identifiedBannedTracks.push(...matchedObjects);
        } else {
          unidentifiedElements.push(uri);
        }
      } else if (!uri.includes('track')) {
        unidentifiedElements.push(uri);
      }
    }

    const mapURIsToNamedDiv = (URIlist) => {
      return URIlist.sort((a, b) =>
        a.playlist > b.playlist ? 1 : b.playlist > a.playlist ? -1 : 0,
      ).map((item) => {
        return (
          <div key={item.uri + item.playlist}>
            <a href={item.uri} target="_blank" rel="noreferrer">
              {item.playlist} - {item.text}
            </a>
          </div>
        );
      });
    };

    const identifiedInListsDiv = mapURIsToNamedDiv(
      identifiedBannedTracks.filter((item) =>
        includedPlaylistNames.includes(item.playlist),
      ),
    );

    const identifiedOffListsDiv = mapURIsToNamedDiv(
      identifiedBannedTracks.filter(
        (item) => !includedPlaylistNames.includes(item.playlist),
      ),
    );

    const unIdentifiedDiv = unidentifiedElements
      .sort((a, b) => (a > b ? 1 : b > a ? -1 : 0))
      .map((item) => {
        return (
          <div key={item}>
            <a href={item} target="_blank" rel="noreferrer">
              {item}
            </a>
          </div>
        );
      });
    return (
      <>
        <h4>Canciones en listas escogidas:</h4>
        {identifiedInListsDiv}
        <h4>Canciones bloqueadas en listas NO escogidas:</h4>
        {identifiedOffListsDiv}
        <h4>Artistas, álbumes, o canciones no identificadas:</h4>
        {unIdentifiedDiv}
      </>
    );
  };

  const refreshButtons = () => {
    return (
      <div style={{}}>
        <Button
          disabled={
            activeSchedule.spotifyID === ''
              ? true
              : false || refreshingScheduleVariety
          }
          size="tiny"
          onClick={() => handleRefreshPL(false, 0)}
          secondary
          loading={refreshingPlaylist}
          style={{ backgroundColor: '#1DB954' }}>
          Solo Spotify
        </Button>
        <Button
          disabled={
            activeSchedule.soundtrackID === ''
              ? true
              : false || refreshingScheduleVariety
          }
          size="tiny"
          onClick={() => handleRefreshPL(true, 0)}
          secondary
          loading={refreshingPlaylist}
          style={{ backgroundColor: '#f23440' }}>
          Spotify y Soundtrack
        </Button>
        <Button
          size="tiny"
          disabled={refreshingScheduleVariety}
          onClick={() => handleRefreshPL(false, 1)}
          secondary
          loading={refreshingPlaylist}
          style={{ backgroundColor: '#3c3c3c', color: 'white' }}>
          Probar UNIVERSO!
        </Button>
      </div>
    );
  };

  return activeSchedule ? (
    <>
      <Table striped fixed celled unstackable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell width={2}>Schedule</Table.HeaderCell>
            <Table.HeaderCell
              width={8}
              key={site?.playlists[scheduleIndex]?.scheduleAlias}>
              <div
                style={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}>
                <Button
                  disabled={refreshingPlaylist || refreshingScheduleVariety}
                  onClick={() => scheduleNavigator('left')}>
                  <FaArrowLeft />
                </Button>
                <span style={{ padding: '0.5rem' }}>
                  {activeSchedule.scheduleAlias}
                </span>
                <Button
                  disabled={refreshingPlaylist || refreshingScheduleVariety}
                  onClick={() => scheduleNavigator('right')}>
                  <FaArrowRight />
                </Button>
              </div>
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {!loading && playlistsASP.length ? (
            <>
              <Table.Row>
                <Table.Cell>MIX Spotify</Table.Cell>
                <Table.Cell>
                  <a
                    href={
                      spotifyPlaylist
                        ? `https://open.spotify.com/playlist/${spotifyPlaylist.id}`
                        : null
                    }
                    target="_blank"
                    rel="noreferrer">
                    {spotifyPlaylist?.name || activeSchedule.spotifyID}
                  </a>
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>MIX Soundtrack</Table.Cell>
                <Table.Cell>
                  {soundtrackPlaylist?.name || activeSchedule.soundtrackID}
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Horas</Table.Cell>
                <Table.Cell>{activeSchedule.hours}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Ratio Peruanas</Table.Cell>
                <Table.Cell>{activeSchedule.ratio}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Factores de variedad</Table.Cell>
                <Table.Cell>{renderVariety()}</Table.Cell>
              </Table.Row>
              {renderPlaylists('PER')}
              {renderPlaylists('EXT')}
              <Table.Row>
                <Table.Cell>Grupo de zonas</Table.Cell>
                <Table.Cell>{site.playlistsGroup}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Mezclar</Table.Cell>
                <Table.Cell>{refreshButtons()}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Bloqueadas (toda la zona)</Table.Cell>
                <Table.Cell>
                  {renderBanned([
                    ...includedPERPlaylists,
                    ...includedEXTPlaylists,
                  ])}
                </Table.Cell>
              </Table.Row>
            </>
          ) : (
            <Table.Row>
              <Table.Cell colSpan={2}>
                <Loader
                  active={true}
                  inline
                  style={{ marginTop: '1rem', width: '100%' }}
                />
              </Table.Cell>
            </Table.Row>
          )}
        </Table.Body>
      </Table>
    </>
  ) : null;
};

export default MoodCreatorTable;
