import React from 'react';
import ReactCountryFlag from 'react-country-flag';
import { Image, List } from 'semantic-ui-react';
import '../assets/styles/History.css';

const History = ({ history }) => {
  const renderItems = () => {
    return history.map((track) => {
      return (
        <List.Item key={track.id + Math.random()}>
          <Image src={track.image} className="listImg" />
          <List.Content className="listText">
            <List.Header>
              {track.artists.map(
                (artist, index, artists) =>
                  artist.name +
                  (artists.length > 1 && index + 1 !== artists.length
                    ? ', '
                    : ''),
              )}
              {track?.isLocal ? <ReactCountryFlag countryCode="PE" /> : null}
            </List.Header>
            <List.Description>
              <p>{track.name}</p>
            </List.Description>
          </List.Content>
        </List.Item>
      );
    });
  };

  return (
    <div className="historyDiv left">
      <List divided relaxed>
        {renderItems()}
      </List>
    </div>
  );
};

export default History;
