import createDataContext from './createDataContext';
import aspbackend from '../api/aspbackend';
import sybbackend from '../api/sybbackend';

const userReducer = (state, action) => {
  switch (action.type) {
    case 'set_site':
      return { ...state, site: action.payload };
    case 'set_user':
      return { ...state, user: action.payload };
    case 'set_playlists':
      return { ...state, playlists: action.payload };
    case 'set_syb_playlists':
      return { ...state, playlistsSYB: action.payload };
    case 'set_sites':
      return { ...state, sites: action.payload };
    case 'set_tracks':
      return { ...state, tracks: action.payload };
    case 'set_admin_info':
      return { ...state, adminInfo: action.payload };
    case 'set_token_validity':
      return { ...state, tokenIsValid: action.payload };

    default:
      return state;
  }
};

const setSite = (dispatch) => async (site) => {
  dispatch({ type: 'set_site', payload: site });
};

const setUser = (dispatch) => async (user) => {
  dispatch({ type: 'set_user', payload: user });
};

const setTokenValidity = (dispatch) => async (validity) => {
  dispatch({ type: 'set_token_validity', payload: validity });
};

const getSYBPlaylists = (dispatch) => async () => {
  const playlists = await aspbackend.get('/soundtrack/playlists');
  const playlistsArray =
    playlists?.data?.data?.account?.musicLibrary?.playlists?.edges;
  dispatch({ type: 'set_syb_playlists', payload: playlistsArray });
};

const getPlaylists = (dispatch) => async () => {
  let playlistsArray = [];
  const playlistsResponse = await aspbackend.get('/playlists');
  for (const playlist of playlistsResponse.data) {
    const playlistItem = await aspbackend.get(
      `/soundtrack/playlist/${playlist.soundtrackID}`,
    );
    playlistsArray.push({
      name: playlist.name,
      sites: playlist.sites,
      spotifyID: playlist.spotifyID,
      ...playlistItem.data?.data?.playlist,
    });
  }
  localStorage.setItem('syb_per_playlists', JSON.stringify(playlistsArray));
  dispatch({ type: 'set_playlists', payload: playlistsArray });
};

const getSites = (dispatch) => async () => {
  const sitesResponse = await aspbackend.get('/sites');
  const orderedSites = sitesResponse.data.sort((a, b) =>
    a.siteDetails.name > b.siteDetails.name
      ? 1
      : b.siteDetails.name > a.siteDetails.name
      ? -1
      : 0,
  );
  localStorage.setItem('sites', JSON.stringify(orderedSites));
  dispatch({ type: 'set_sites', payload: orderedSites });
};

const getTracks = (dispatch) => async () => {
  const tracksResponse = await aspbackend.get('/tracks');
  const orderedTracks = tracksResponse.data.sort((a, b) =>
    a.artists > b.artists ? 1 : b.artists > a.artists ? -1 : 0,
  );
  dispatch({ type: 'set_tracks', payload: orderedTracks });
};

const getAdminInfo = (dispatch) => async (stateToken) => {
  const token = stateToken || localStorage.getItem('token');
  const adminResponse = await aspbackend.get('/admin', {
    headers: { 'x-access-token': token },
  });
  dispatch({ type: 'set_admin_info', payload: adminResponse.data });
};

export const { Context, Provider } = createDataContext(
  userReducer,
  {
    getAdminInfo,
    getPlaylists,
    getSYBPlaylists,
    getSites,
    setSite,
    setUser,
    getTracks,
    setTokenValidity,
  },
  {
    playlists: null,
    playlistsSYB: null,
    sites: null,
    site: null,
    user: null,
    adminInfo: {},
    tokenIsValid: false,
  },
);
