import React, { useEffect, useState, useRef } from 'react';
import { Carousel } from 'react-responsive-carousel';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Button } from 'semantic-ui-react';
import { IoLogoInstagram, IoMdExit } from 'react-icons/io';
import 'react-lazy-load-image-component/src/effects/blur.css';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import recordImg from '../assets/images/record.png';
import ReactCountryFlag from 'react-country-flag';
import { Centrifuge } from 'centrifuge';
import aspbackend from '../api/aspbackend';
import '../assets/styles/NowPlaying.css';
import '../assets/styles/NowPlayingMini.css';

const NowPlayingAdminMini = ({ site }) => {
  let siteID = site?.siteID;
  const [currentArtist, setCurrentArtist] = useState(null);
  const [currentTrack, setCurrentTrack] = useState(null);
  const [centrifugeObj, _setCentrifugeObj] = useState(null);
  const [subObj, setSubObj] = useState(null);
  const [localPlayerStatus, setLocalPlayerStatus] = useState('active');
  const [downloadingVideo, setDownloadingVideo] = useState(false);

  const centrifugeObjRef = useRef(centrifugeObj);
  const setCentrifugeObj = (val) => {
    centrifugeObjRef.current = val;
    _setCentrifugeObj(val);
  };

  const wssSubscribe = async () => {
    await aspbackend.get(`token`).then(async (response) => {
      // Use WebSocket transport endpoint.
      const centrifuge = new Centrifuge(
        'wss://centrifugo.aquisuenaperu.com/connection/websocket',
        {
          token: response.data,
        },
      );
      // console.log('connecting to', centrifugeObjRef);
      setCentrifugeObj(centrifuge);

      // Allocate Subscription to a channel
      const sub = centrifuge.newSubscription(`site_update_${siteID}`);
      setSubObj(sub);

      // React on `news` channel real-time publications.
      sub.on('publication', function (ctx) {
        if (ctx?.data?.site) {
          setCurrentTrack(ctx.data.site.currentTrack);
        }
        if (ctx?.data?.artist) {
          setCurrentArtist(ctx.data.artist);
        }
      });

      // Trigger subscribe process.
      sub.subscribe();

      // Trigger actual connection establishement.
      centrifuge.connect();
    });
  };

  const getInfo = async () => {
    await aspbackend.get(`site/${siteID}`).then(async (response) => {
      setCurrentTrack(response.data.currentTrack);
      let artistName =
        response.data?.currentTrack?.artists.length === 1 ||
        (response.data?.currentTrack?.artists.length > 1 &&
          !response.data?.currentTrack?.artists[0].name.includes(' & '))
          ? response.data?.currentTrack?.artists[0].name.normalize('NFD')
          : response.data?.currentTrack?.artists[1].name.normalize('NFD');
      await aspbackend
        .get(`artist?name=${encodeURIComponent(artistName)}`)
        .then((response) => {
          setCurrentArtist(response.data);
        });
    });
  };

  const onFocus = () => {
    getInfo();
    // console.log('reconnecting if object found:', centrifugeObjRef);
    if (centrifugeObjRef.current) {
      centrifugeObjRef.current.connect();
    }
  };

  const onBlur = () => {
    // console.log('disconnecting if object found:', centrifugeObjRef);
    if (centrifugeObjRef.current) {
      centrifugeObjRef.current.disconnect();
    }
  };

  useEffect(() => {
    if (subObj) {
      subObj.unsubscribe();
    }
    wssSubscribe();
    getInfo();
    window.addEventListener('focus', onFocus);
    window.addEventListener('blur', onBlur);
    return () => {
      window.removeEventListener('focus', onFocus);
      window.removeEventListener('blur', onBlur);
    };
  }, [site?.siteID]);

  useEffect(() => {
    setLocalPlayerStatus(
      site && site.playerStatus !== 'playing' ? 'inactive' : 'active',
    );
  }, [currentTrack, currentArtist?.name, site?.playerStatus]);

  const renderThumbs = () => {};

  const carouselItemFlag = (isLocal) => {
    return isLocal ? (
      <ReactCountryFlag className="flag" countryCode="PE" />
    ) : null;
  };

  const carouselItemYear = (date) => {
    return date ? <div className="year">{date.slice(0, 4)}</div> : null;
  };

  const carouselItems = [
    <div key={1}>
      {carouselItemFlag(currentTrack?.isLocal)}
      {currentTrack?.album
        ? carouselItemYear(currentTrack?.album?.releaseDate?.timestamp)
        : null}
      <LazyLoadImage
        alt={'album cover of this song'}
        effect="blur"
        placeholder={
          <img src={recordImg} className="nowPlayingImageSlide" width={500} />
        }
        src={
          site?.sourceType === 'soundtrack'
            ? currentTrack?.album?.display?.image?.sizes?.teaser
            : currentTrack?.image
        }
        className="nowPlayingImageSlide"
      />
    </div>,
  ];

  const handleDownloadVideo = async () => {
    setDownloadingVideo(true);
    await fetch(
      `https://video.aquisuenaperu.com/api/instavideo?siteID=${site.siteID}`,
      // `http://localhost:3004/api/instavideo?siteID=${site.siteID}`,
    ).then(() => {
      const url = 'https://video.aquisuenaperu.com/videos/merged.mp4';
      fetch(url)
        .then((r) => r.blob())
        .then((blob) => {
          const blobURL = window.URL.createObjectURL(new Blob([blob]));
          const fileName = url.split('/').pop();
          const link = document.createElement('a');
          link.href = blobURL;
          // link.href = 'http://localhost:3004/videos/merged.mp4';
          link.setAttribute('download', fileName);
          // link.setAttribute('target', '_blank');
          document.body.appendChild(link);
          link.click();
          link.remove();
        });
    });
    setDownloadingVideo(false);
  };

  return (
    <div className="nowPlayingDiv mini">
      {localPlayerStatus && localPlayerStatus === 'inactive' ? (
        <div className="nowPlayingTextDiv">
          <p className="nowPlayingTextSong">
            · Reproductor apagado o sin Internet ·
          </p>
        </div>
      ) : (
        <div className="nowPlayingTextDiv">
          <div>
            <p className="nowPlayingTextArtist">
              {currentTrack?.artists.length === 1
                ? currentTrack?.artists[0].name
                : currentTrack?.artists
                    .filter((artist) => !artist.name.includes(' & '))
                    .map(
                      (artist, index, artists) =>
                        artist.name +
                        (artists.length > 1 && index + 1 !== artists.length
                          ? ', '
                          : ''),
                    )}
            </p>
            <p className="nowPlayingTextSong">
              {site?.sourceType === 'soundtrack'
                ? currentTrack?.title
                : currentTrack?.name}
            </p>
          </div>
        </div>
      )}

      {localPlayerStatus && localPlayerStatus === 'inactive' ? (
        <>
          <img
            src={'https://aquisuenaperu.com/images/danger.png'}
            className="nowPlayingImageSlide"
          />
        </>
      ) : (
        <div style={{ display: 'flex' }}>
          <Carousel
            showArrows={true}
            infiniteLoop={true}
            emulateTouch={true}
            preventMovementUntilSwipeScrollTolerance={true}
            swipeScrollTolerance={50}
            renderThumbs={renderThumbs}
            showStatus={false}>
            {carouselItems.filter((item) => item !== null)}
          </Carousel>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Button
              size="tiny"
              icon
              disabled={!currentTrack?.isLocal}
              className="instaButton"
              style={{ padding: 0 }}
              loading={downloadingVideo}
              onClick={handleDownloadVideo}>
              <IoLogoInstagram style={{ color: 'black', fontSize: '2rem' }} />
            </Button>
            <a
              target="_blank"
              href={`https://aquisuenaperu.com/sitios/${site?.siteID}`}>
              <IoMdExit style={{ color: 'black', fontSize: '2rem' }} />
            </a>
          </div>
        </div>
      )}
    </div>
  );
};

export default NowPlayingAdminMini;
