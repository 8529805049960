import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './components/App';
import { BrowserRouter } from 'react-router-dom';
import { Provider as SpotifyProvider } from './context/SpotifyContext';
import { Provider as GeneralProvider } from './context/GeneralContext';
import './assets/styles/index.css';
import 'semantic-ui-css/semantic.min.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <GeneralProvider>
    <SpotifyProvider>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </SpotifyProvider>
    ,
  </GeneralProvider>,
);
