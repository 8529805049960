import React, { useContext, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import ReactCountryFlag from 'react-country-flag';
import {
  Button,
  Checkbox,
  Dropdown,
  Input,
  Loader,
  Table,
} from 'semantic-ui-react';
import '../assets/styles/Admin.css';
import {
  IoIosArrowDropdownCircle,
  IoIosArrowDropleftCircle,
} from 'react-icons/io';
import aspbackend from '../api/aspbackend';
import SpotifyWebPlayer from './SpotifyWebPlayer';
import FiltersModal from './FiltersModal';
import { Context as GeneralContext } from '../context/GeneralContext';
import { Context as SpotifyContext } from '../context/SpotifyContext';
import NowPlayingAdminMini from './NowPlayingMini';
import GlobalSitesView from './GlobalSitesView';

const Admin = () => {
  const location = useLocation();
  const navigate = useNavigate();
  let _site = JSON.parse(localStorage.getItem('site')) || location?.state?.site;
  if (_site) {
    localStorage.setItem('site', JSON.stringify(_site));
  }
  const childRef = useRef(null);

  const {
    getAdminInfo,
    setSite,
    getSites,
    getSYBPlaylists,
    setUser,
    state: { site, sites, playlistsSYB, user, adminInfo },
  } = useContext(GeneralContext);

  const {
    getPlaylistsASP,
    state: { playlistsASP },
  } = useContext(SpotifyContext);

  const [editable, setEditable] = useState(false);
  const [checkingEditable, setCheckingEditable] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [saveable, setSaveable] = useState(true);
  const [originalSite, setOriginalSite] = useState(_site);
  const [aliasValues, setAliasValues] = useState(
    _site.playlists.map((p) => p.scheduleAlias),
  );
  const [hoursValues, setHoursValues] = useState(
    _site.playlists.map((p) => p.hours),
  );
  const [ratioValues, setRatioValues] = useState(
    _site.playlists.map((p) => p.ratio),
  );
  const [spotifyIDvalues, setSpotifyIDvalues] = useState(
    _site.playlists.map((p) => p.spotifyID),
  );
  const [soundtrackIDvalues, setSoundtrackIDvalues] = useState(
    _site.playlists.map((p) => p.soundtrackID),
  );
  const [featuresArray, setFeaturesArray] = useState(
    _site.playlists.map((p) => p.audioFeatures || {}),
  );
  const [perWeightsArray, setPerWeightsArray] = useState(
    _site.playlists.map((p) => p.perWeights || undefined),
  );
  const [extWeightsArray, setExtWeightsArray] = useState(
    _site.playlists.map((p) => p.extWeights || undefined),
  );
  const [scheduleRatios, setScheduleRatios] = useState(
    _site.playlists.map((p) => p.scheduleRatios || {}),
  );
  const [refreshingScheduleRatioArray, setRefreshingScheduleRatioArray] =
    useState(_site.playlists.map((p) => null));
  const [bannedURIs, setBannedURIs] = useState(
    _site.bannedURIs !== '' ? _site.bannedURIs.join(',') : '',
  );

  const [openAdvancedModal, setOpenAdvancedModal] = useState(false);
  const [activeModalIndex, setActiveModalIndex] = useState(0);

  const [cols, setCols] = useState(_site.playlists.length || 1);
  const [refreshableScheduleAlias, setRefreshableScheduleAlias] = useState();
  const [refreshableScheduleIndex, setRefreshableScheduleIndex] = useState();
  const [refreshModalVisible, setRefreshModalVisible] = useState(false);
  const [refreshingPlaylist, setRefreshingPlaylist] = useState(false);
  const [refreshingScheduleRatioAll, setRefreshingScheduleRatioAll] =
    useState(false);

  const [listsVisible, setListsVisible] = useState(null);

  useEffect(() => {
    // initial site
    localStorage.removeItem('sites');
    setSite(_site);
    getSites();
    if (adminInfo.sc && adminInfo.ss && adminInfo.srt && adminInfo.sybt) {
      getPlaylistsASP(adminInfo.sc, adminInfo.ss, adminInfo.srt);
      getSYBPlaylists(adminInfo.sybt);
    }
  }, [adminInfo]);

  useEffect(() => {
    if (user && user.role === 'admin') {
      getAdminInfo(user.token);
    }
  }, [user]);

  useEffect(() => {
    setSaveable(true);
    setErrorMsg('');
  }, [openAdvancedModal]);

  const handleSiteState = async (e, { value }) => {
    if (_site.siteID !== '0') {
      childRef.current.cleanModalState();
    }
    if (value) {
      navigate(`/sitios/${value}/admin`, { replace: true });
      const newSite = sites.filter((s) => s.siteID === value)[0];
      setSite(newSite);
      setOriginalSite(newSite);
      setCols(newSite.playlists.length);
      setAliasValues(newSite.playlists.map((p) => p.scheduleAlias));
      setRatioValues(newSite.playlists.map((p) => p.ratio));
      setHoursValues(newSite.playlists.map((p) => p.hours));
      setSpotifyIDvalues(newSite.playlists.map((p) => p.spotifyID));
      setSoundtrackIDvalues(newSite.playlists.map((p) => p.soundtrackID));
      setRefreshableScheduleAlias(null);
      setRefreshingPlaylist(false);
      setRefreshModalVisible(false);
      setFeaturesArray(newSite.playlists.map((p) => p.audioFeatures || {}));
      setPerWeightsArray(
        newSite.playlists.map((p) => p.perWeights) || undefined,
      );
      setExtWeightsArray(
        newSite.playlists.map((p) => p.extWeights) || undefined,
      );
      setScheduleRatios(newSite.playlists.map((p) => p.scheduleRatios || {}));
      setRefreshingScheduleRatioArray(newSite.playlists.map((o) => false));
      setBannedURIs(newSite.bannedURIs?.join(','));
      localStorage.setItem('site', JSON.stringify(newSite));
      await getSites();
    }
  };

  const formatDate = (date) => {
    const newDate = new Date(date);
    return newDate.toLocaleString('en-GB', { timeZone: 'America/Lima' });
  };

  const handleNewCol = () => {
    const newPlaylist = {
      scheduleAlias: 'nuevo',
      spotifyID: '',
      soundtrackID: '',
      perList: [],
      extList: [],
      hours: 8,
      ratio: 0.5,
    };
    setAliasValues([...aliasValues, 'nuevo']);
    setRatioValues([...ratioValues, 0.5]);
    setHoursValues([...hoursValues, 8]);
    setSpotifyIDvalues([...spotifyIDvalues, '']);
    setSoundtrackIDvalues([...soundtrackIDvalues, '']);
    setSite({ ...site, playlists: [...site.playlists, newPlaylist] });
    setFeaturesArray([...featuresArray, {}]);
    setPerWeightsArray([...perWeightsArray, undefined]);
    setExtWeightsArray([...extWeightsArray, undefined]);
    setScheduleRatios([...scheduleRatios, {}]);
  };

  const handleRefreshModal = (scheduleAlias, index) => {
    setRefreshModalVisible(true);
    setRefreshableScheduleAlias(scheduleAlias);
    setRefreshableScheduleIndex(index);
  };

  const handleColDelete = (index) => {
    const newPlaylists = site.playlists.filter((_, i) => i !== index);
    setSite({
      ...site,
      playlists: newPlaylists,
    });
    setAliasValues(newPlaylists.map((p) => p.scheduleAlias));
    setRatioValues(newPlaylists.map((p) => p.ratio));
    setHoursValues(newPlaylists.map((p) => p.hours));
    setSpotifyIDvalues(newPlaylists.map((p) => p.spotifyID));
    setSoundtrackIDvalues(newPlaylists.map((p) => p.soundtrackID));
    setFeaturesArray(newPlaylists.map((p) => p.audioFeatures));
    setPerWeightsArray(newPlaylists.map((p) => p.perWeights));
    setExtWeightsArray(newPlaylists.map((p) => p.extWeights));
    setCols(newPlaylists.length - 1);
  };

  const handleSitePlaylistsChange = (playlistName, playlistId, index) => {
    const isLocal = playlistName.includes('PER');
    const newSitePlaylists = site.playlists.map((obj, i) => {
      if (i === index) {
        if (isLocal) {
          if (
            perWeightsArray[index] &&
            Object.keys(perWeightsArray[index])?.length
          ) {
            setSaveable(false);
            setErrorMsg('Revisa los pesos por lista si quieres grabar');
          }
          if (obj.perList.includes(playlistId)) {
            setPerWeightsArray(
              perWeightsArray.map((wObj, ind) => {
                if (wObj && wObj[playlistId] && ind === index) {
                  delete wObj[playlistId];
                }
                return wObj;
              }),
            );
            return {
              ...obj,
              perList: obj.perList.filter((p) => p !== playlistId),
            };
          } else {
            return { ...obj, perList: [...obj.perList, playlistId] };
          }
        } else {
          if (
            extWeightsArray[index] &&
            Object.keys(extWeightsArray[index])?.length
          ) {
            setSaveable(false);
            setErrorMsg('Revisa los pesos por lista si quieres grabar');
          }
          if (obj.extList.includes(playlistId)) {
            setExtWeightsArray(
              extWeightsArray.map((wObj, ind) => {
                if (wObj && wObj[playlistId] && ind === index) {
                  delete wObj[playlistId];
                }
                return wObj;
              }),
            );
            return {
              ...obj,
              extList: obj.extList.filter((p) => p !== playlistId),
            };
          } else {
            return { ...obj, extList: [...obj.extList, playlistId] };
          }
        }
      } else {
        return obj;
      }
    });
    setSite({ ...site, playlists: newSitePlaylists });
  };

  const handleParentFeaturesObject = (object, index) => {
    setFeaturesArray(
      featuresArray.map((e, i) => {
        if (i === index) {
          return object;
        }
        return e;
      }),
    );
  };

  const handleParentWeightsObject = (perWeights, extWeights, index) => {
    setPerWeightsArray(
      perWeightsArray.map((obj, i) => {
        if (i === index) {
          return perWeights;
        }
        return obj;
      }),
    );
    setExtWeightsArray(
      extWeightsArray.map((obj, i) => {
        if (i === index) {
          return extWeights;
        }
        return obj;
      }),
    );
  };

  const advancedModal = () => {
    return (
      <FiltersModal
        key={site.siteID}
        site={site}
        activeModalIndex={activeModalIndex}
        featuresArray={featuresArray}
        perWeightsArray={perWeightsArray}
        extWeightsArray={extWeightsArray}
        playlistsASP={playlistsASP}
        openAdvancedModal={openAdvancedModal}
        setOpenAdvancedModal={setOpenAdvancedModal}
        handleParentFeaturesObject={handleParentFeaturesObject}
        handleParentWeightsObject={handleParentWeightsObject}
        ref={childRef}
      />
    );
  };

  const objectToList = (object) => {
    let string = [];
    for (const key in object) {
      if (key === 'yearRanges') {
        string.push(
          <li key={key}>
            {key}: {object[key].map((e) => e[0] + 's ')}
          </li>,
        );
      } else if (key === 'enableExplicit') {
        string.push(
          <li key={key}>
            {key}: {object[key].toString()}
          </li>,
        );
      } else {
        string.push(
          <li key={key}>
            {key}: {object[key][0]}-{object[key][1]}
          </li>,
        );
      }
    }
    return string;
  };

  const siteSelector = () => {
    const siteOptions = sites
      .filter((site) => site.sourceType === 'soundtrack')
      .map((site) => {
        return { text: site.siteDetails.name, value: site.siteID };
      });
    return (
      <Dropdown
        placeholder="Sitio"
        clearable
        selection
        disabled={editable}
        options={siteOptions}
        value={site.siteID}
        onChange={handleSiteState}
        style={{
          margin: '1rem 0',
          display: 'block',
        }}
      />
    );
  };

  const toggleLists = (listGroup) => {
    listsVisible === listGroup
      ? setListsVisible(null)
      : setListsVisible(listGroup);
  };

  const renderPlaylists = (playlists) => {
    const perPlaylists = playlists
      .filter((p) => p.name.includes('PER-'))
      .sort((a, b) => a.name.localeCompare(b.name));
    const casPlaylists = playlists
      .filter((p) => p.name.includes('CAS-'))
      .sort((a, b) => a.name.localeCompare(b.name));
    const ingPlaylists = playlists
      .filter((p) => p.name.includes('ING-'))
      .sort((a, b) => a.name.localeCompare(b.name));
    const extPlaylists = playlists
      .filter((p) => site?.allowedEXTPlaylists?.includes(p.name))
      .sort((a, b) => a.name.localeCompare(b.name));
    return (
      <Table striped fixed celled unstackable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Playlist</Table.HeaderCell>
            {site?.playlists?.map((sitePlaylist, index) => {
              return (
                <Table.HeaderCell
                  key={sitePlaylist.scheduleAlias + String(index)}>
                  {!editable ? (
                    <div>
                      <span>{sitePlaylist.scheduleAlias}</span>
                      <Button
                        style={{
                          borderRadius: '12.5px',
                          fontSize: '0.8rem',
                          lineHeight: 1,
                          height: '25px',
                          marginLeft: 2,
                          padding: '2px 5px',
                          verticalAlign: 'middle',
                          width: '25px',
                          backgroundColor: '#152AF7',
                          color: 'white',
                        }}
                        onClick={() =>
                          handleRefreshModal(sitePlaylist.scheduleAlias, index)
                        }>
                        R
                      </Button>
                    </div>
                  ) : (
                    <div>
                      <Input
                        size="mini"
                        className="compactInput"
                        maxLength="20"
                        placeholder="alias"
                        value={aliasValues[index]}
                        onChange={(e) => {
                          setAliasValues(
                            aliasValues.map((a, i) => {
                              if (i === index) {
                                if (!e.target.value) {
                                  alert('No puedes dejar en blanco el alias');
                                  return a;
                                }
                                return e.target.value;
                              } else {
                                return a;
                              }
                            }),
                          );
                        }}
                      />
                      <Button
                        style={{
                          borderRadius: '12.5px',
                          fontSize: '0.8rem',
                          lineHeight: 1,
                          height: '25px',
                          marginLeft: 2,
                          padding: '2px 5px',
                          verticalAlign: 'middle',
                          width: '25px',
                          backgroundColor: '#FF092F',
                          color: 'white',
                        }}
                        onClick={() => handleColDelete(index)}>
                        X
                      </Button>
                    </div>
                  )}
                </Table.HeaderCell>
              );
            })}
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {/* <Table.Row>
            <Table.Cell colSpan={cols + 1}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}>
                <h4>LISTAS</h4>
                <Loader active={!playlistsASP.length} inline />
                <Button
                  size="large"
                  onClick={toggleLists}
                  style={{ backgroundColor: 'rgba(0,0,0,0)', padding: 0 }}>
                  <h3>
                    {listsVisible ? (
                      <IoIosArrowDropdownCircle style={{ fontSize: '2rem' }} />
                    ) : (
                      <IoIosArrowDropleftCircle style={{ fontSize: '2rem' }} />
                    )}
                  </h3>
                </Button>
              </div>
            </Table.Cell>
          </Table.Row> */}
        </Table.Body>
        <Table.Body>
          <Table.Row>
            <Table.Cell colSpan={cols + 1}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}>
                <h3>
                  LISTAS PER
                  <ReactCountryFlag countryCode="PE" />
                </h3>
                <Loader active={!playlistsASP.length} inline />
                <Button
                  size="large"
                  onClick={() => toggleLists('PER')}
                  style={{ backgroundColor: 'rgba(0,0,0,0)', padding: 0 }}>
                  <h3>
                    {listsVisible === 'PER' ? (
                      <IoIosArrowDropdownCircle style={{ fontSize: '2rem' }} />
                    ) : (
                      <IoIosArrowDropleftCircle style={{ fontSize: '2rem' }} />
                    )}
                  </h3>
                </Button>
              </div>
            </Table.Cell>
          </Table.Row>
          {listsVisible === 'PER' &&
            perPlaylists
              ?.filter((pl) => pl.tracks.total !== 0)
              .map((playlist) => {
                return (
                  <Table.Row key={playlist.id}>
                    <Table.Cell>
                      {playlist.name}
                      <p style={{ color: 'grey' }}>
                        {playlist.tracks.total} canciones
                      </p>
                    </Table.Cell>
                    {site.playlists.map((sitePlaylist, index) => {
                      return (
                        <Table.Cell key={sitePlaylist.scheduleAlias}>
                          <Checkbox
                            checked={
                              sitePlaylist.perList.includes(playlist.id) ||
                              sitePlaylist.extList.includes(playlist.id)
                            }
                            onChange={() =>
                              handleSitePlaylistsChange(
                                playlist.name,
                                playlist.id,
                                index,
                              )
                            }
                            readOnly={!editable}
                          />
                        </Table.Cell>
                      );
                    })}
                  </Table.Row>
                );
              })}
          <Table.Row>
            <Table.Cell colSpan={cols + 1}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}>
                <h3>
                  LISTAS CAS
                  <ReactCountryFlag countryCode="ES" />
                </h3>
                <Loader active={!playlistsASP.length} inline />
                <Button
                  size="large"
                  onClick={() => toggleLists('CAS')}
                  style={{ backgroundColor: 'rgba(0,0,0,0)', padding: 0 }}>
                  <h3>
                    {listsVisible === 'CAS' ? (
                      <IoIosArrowDropdownCircle style={{ fontSize: '2rem' }} />
                    ) : (
                      <IoIosArrowDropleftCircle style={{ fontSize: '2rem' }} />
                    )}
                  </h3>
                </Button>
              </div>
            </Table.Cell>
          </Table.Row>
          {listsVisible === 'CAS' &&
            casPlaylists
              ?.filter((pl) => pl.tracks.total !== 0)
              .map((playlist) => {
                return (
                  <Table.Row key={playlist.id}>
                    <Table.Cell>
                      {playlist.name}
                      <p style={{ color: 'grey' }}>
                        {playlist.tracks.total} canciones
                      </p>
                    </Table.Cell>
                    {site.playlists.map((sitePlaylist, index) => {
                      return (
                        <Table.Cell key={sitePlaylist.scheduleAlias}>
                          <Checkbox
                            checked={
                              sitePlaylist.perList.includes(playlist.id) ||
                              sitePlaylist.extList.includes(playlist.id)
                            }
                            onChange={() =>
                              handleSitePlaylistsChange(
                                playlist.name,
                                playlist.id,
                                index,
                              )
                            }
                            readOnly={!editable}
                          />
                        </Table.Cell>
                      );
                    })}
                  </Table.Row>
                );
              })}
          <Table.Row>
            <Table.Cell colSpan={cols + 1}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}>
                <h3>
                  LISTAS ING
                  <ReactCountryFlag countryCode="GB" />
                </h3>
                <Loader active={!playlistsASP.length} inline />
                <Button
                  size="large"
                  onClick={() => toggleLists('ING')}
                  style={{ backgroundColor: 'rgba(0,0,0,0)', padding: 0 }}>
                  <h3>
                    {listsVisible === 'ING' ? (
                      <IoIosArrowDropdownCircle style={{ fontSize: '2rem' }} />
                    ) : (
                      <IoIosArrowDropleftCircle style={{ fontSize: '2rem' }} />
                    )}
                  </h3>
                </Button>
              </div>
            </Table.Cell>
          </Table.Row>
          {listsVisible === 'ING' &&
            ingPlaylists
              ?.filter((pl) => pl.tracks.total !== 0)
              .map((playlist) => {
                return (
                  <Table.Row key={playlist.id}>
                    <Table.Cell>
                      {playlist.name}
                      <p style={{ color: 'grey' }}>
                        {playlist.tracks.total} canciones
                      </p>
                    </Table.Cell>
                    {site.playlists.map((sitePlaylist, index) => {
                      return (
                        <Table.Cell key={sitePlaylist.scheduleAlias}>
                          <Checkbox
                            checked={
                              sitePlaylist.perList.includes(playlist.id) ||
                              sitePlaylist.extList.includes(playlist.id)
                            }
                            onChange={() =>
                              handleSitePlaylistsChange(
                                playlist.name,
                                playlist.id,
                                index,
                              )
                            }
                            readOnly={!editable}
                          />
                        </Table.Cell>
                      );
                    })}
                  </Table.Row>
                );
              })}
          {extPlaylists.length ? (
            <Table.Row>
              <Table.Cell colSpan={cols + 1}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  }}>
                  <h3>EXT PLAYLISTS</h3>
                  <Loader active={!playlistsASP.length} inline />
                  <Button
                    size="large"
                    onClick={() => toggleLists('EXT')}
                    style={{ backgroundColor: 'rgba(0,0,0,0)', padding: 0 }}>
                    <h3>
                      {listsVisible === 'EXT' ? (
                        <IoIosArrowDropdownCircle
                          style={{ fontSize: '2rem' }}
                        />
                      ) : (
                        <IoIosArrowDropleftCircle
                          style={{ fontSize: '2rem' }}
                        />
                      )}
                    </h3>
                  </Button>
                </div>
              </Table.Cell>
            </Table.Row>
          ) : null}
          {listsVisible === 'EXT' &&
            extPlaylists
              ?.filter((pl) => pl.tracks.total !== 0)
              .map((playlist) => {
                return (
                  <Table.Row key={playlist.id}>
                    <Table.Cell>
                      {playlist.name}
                      <p style={{ color: 'grey' }}>
                        {playlist.tracks.total} canciones
                      </p>
                    </Table.Cell>
                    {site.playlists.map((sitePlaylist, index) => {
                      return (
                        <Table.Cell key={sitePlaylist.scheduleAlias}>
                          <Checkbox
                            checked={
                              sitePlaylist.perList.includes(playlist.id) ||
                              sitePlaylist.extList.includes(playlist.id)
                            }
                            onChange={() =>
                              handleSitePlaylistsChange(
                                playlist.name,
                                playlist.id,
                                index,
                              )
                            }
                            readOnly={!editable}
                          />
                        </Table.Cell>
                      );
                    })}
                  </Table.Row>
                );
              })}
        </Table.Body>
        <Table.Body>
          <Table.Row>
            <Table.Cell style={{ borderWidth: '0.5rem 1px' }}>
              Spotify PL
            </Table.Cell>
            {site?.playlists?.map((sitePlaylist, index) => {
              const spotifyPlaylist = playlistsASP.filter(
                (p) => p.id === sitePlaylist.spotifyID,
              )[0];
              return (
                <Table.Cell
                  style={{ borderWidth: '0.5rem 1px' }}
                  key={sitePlaylist.scheduleAlias}>
                  {!editable ? (
                    <a
                      href={
                        spotifyPlaylist
                          ? `https://open.spotify.com/playlist/${spotifyPlaylist.id}`
                          : null
                      }
                      target="_blank">
                      {spotifyPlaylist?.name}
                    </a>
                  ) : (
                    <Input
                      size="mini"
                      fluid
                      placeholder="spotifyID"
                      value={spotifyIDvalues[index]}
                      onChange={(e) => {
                        setSpotifyIDvalues(
                          spotifyIDvalues.map((a, i) => {
                            if (i === index) {
                              return e.target.value;
                            } else {
                              return a;
                            }
                          }),
                        );
                      }}
                    />
                  )}
                </Table.Cell>
              );
            })}
          </Table.Row>
          <Table.Row>
            <Table.Cell>Soundtrack PL</Table.Cell>
            {playlistsSYB &&
              site.playlists.map((sitePlaylist, index) => {
                return (
                  <Table.Cell key={sitePlaylist.scheduleAlias}>
                    {!editable ? (
                      playlistsSYB.filter(
                        (p) => p.node.id === sitePlaylist.soundtrackID,
                      )[0]?.node.name
                    ) : (
                      <Input
                        fluid
                        size="mini"
                        placeholder="soundtrackID"
                        value={soundtrackIDvalues[index]}
                        onChange={(e) => {
                          setSoundtrackIDvalues(
                            soundtrackIDvalues.map((a, i) => {
                              if (i === index) {
                                return e.target.value;
                              } else {
                                return a;
                              }
                            }),
                          );
                        }}
                      />
                    )}
                  </Table.Cell>
                );
              })}
          </Table.Row>
          <Table.Row>
            <Table.Cell>Horas</Table.Cell>
            {site?.playlists?.map((sitePlaylist, index) => {
              return (
                <Table.Cell key={sitePlaylist.scheduleAlias}>
                  {!editable ? (
                    sitePlaylist.hours
                  ) : (
                    <Input
                      size="mini"
                      className="compactInput"
                      maxLength="20"
                      placeholder="horas"
                      value={hoursValues[index]}
                      onChange={(e) => {
                        setHoursValues(
                          hoursValues.map((a, i) => {
                            if (i === index) {
                              if (!e.target.value) {
                                alert('No puedes dejar en blanco las horas');
                                return a;
                              }
                              return e.target.value;
                            } else {
                              return a;
                            }
                          }),
                        );
                      }}
                    />
                  )}
                </Table.Cell>
              );
            })}
          </Table.Row>
          <Table.Row>
            <Table.Cell>Ratio</Table.Cell>
            {site?.playlists?.map((sitePlaylist, index) => {
              return (
                <Table.Cell key={sitePlaylist.scheduleAlias}>
                  {!editable ? (
                    sitePlaylist.ratio
                  ) : (
                    <Input
                      size="mini"
                      className="compactInput"
                      maxLength="20"
                      placeholder="ratio"
                      value={ratioValues[index]}
                      onChange={(e) => {
                        setRatioValues(
                          ratioValues.map((a, i) => {
                            if (i === index) {
                              if (!e.target.value) {
                                alert('No puedes dejar en blanco el ratio');
                                return a;
                              }
                              return e.target.value;
                            } else {
                              return a;
                            }
                          }),
                        );
                      }}
                    />
                  )}
                </Table.Cell>
              );
            })}
          </Table.Row>
          <Table.Row verticalAlign="top">
            <Table.Cell>Avanzado</Table.Cell>
            {site?.playlists?.map((_, index) => {
              const perIncludedLists =
                playlistsASP &&
                playlistsASP.filter((p) =>
                  site.playlists[index]?.perList.includes(p.id),
                );
              const extIncludedLists =
                playlistsASP &&
                playlistsASP.filter((p) =>
                  site.playlists[index]?.extList.includes(p.id),
                );
              return (
                <Table.Cell key={index}>
                  <Button
                    disabled={!editable}
                    onClick={() => {
                      setOpenAdvancedModal(true);
                      setActiveModalIndex(index);
                    }}
                    color="blue"
                    size="tiny">
                    Modificar
                  </Button>

                  {featuresArray[index]?.perFeatures &&
                  Object.keys(featuresArray[index].perFeatures).length !== 0 ? (
                    <>
                      <h5>Filtros de peruanas:</h5>
                      <ul style={{ paddingLeft: '1rem' }}>
                        {objectToList(featuresArray[index]?.perFeatures)}
                      </ul>
                    </>
                  ) : null}
                  {site.playlists[index]?.perWeights &&
                  Object.keys(site.playlists[index]?.perWeights)?.length > 1 ? (
                    <>
                      <h5>Pesos de peruanas:</h5>
                      {perIncludedLists.map((pl) => (
                        <ul key={pl.id} style={{ paddingLeft: '1rem' }}>
                          <li>
                            {pl.name} -{' '}
                            {site.playlists[index].perWeights[pl.id]}%
                          </li>
                        </ul>
                      ))}
                    </>
                  ) : null}
                  {featuresArray[index]?.extFeatures &&
                  Object.keys(featuresArray[index].extFeatures).length !== 0 ? (
                    <>
                      <h5>Filtros de extranjeras:</h5>
                      <ul style={{ paddingLeft: '1rem' }}>
                        {objectToList(featuresArray[index]?.extFeatures)}
                      </ul>
                    </>
                  ) : null}
                  {site.playlists[index]?.extWeights &&
                  Object.keys(site.playlists[index]?.extWeights)?.length > 1 ? (
                    <>
                      <h5>Pesos de extranjeras:</h5>
                      {extIncludedLists.map((pl) => (
                        <ul key={pl.id} style={{ paddingLeft: '1rem' }}>
                          <li>
                            {pl.name} -{' '}
                            {site.playlists[index].extWeights[pl.id]}%
                          </li>
                        </ul>
                      ))}
                    </>
                  ) : null}
                  {featuresArray[index]?.listFeatures &&
                  Object.keys(featuresArray[index].listFeatures).length !==
                    0 ? (
                    <>
                      <h5>Por lista:</h5>
                      <ul style={{ paddingLeft: '1rem' }}>
                        {Object.keys(featuresArray[index]?.listFeatures).map(
                          (key) => {
                            const keyElements = objectToList(
                              featuresArray[index].listFeatures[key],
                            );
                            return (
                              <div key={key} style={{ marginTop: '1rem' }}>
                                <strong>
                                  {keyElements.length
                                    ? playlistsASP &&
                                      playlistsASP.filter(
                                        (p) => p.id === key,
                                      )[0]?.name
                                    : null}
                                </strong>
                                <ul style={{ paddingLeft: '1rem' }}>
                                  {keyElements}
                                </ul>
                              </div>
                            );
                          },
                        )}
                      </ul>
                    </>
                  ) : null}
                </Table.Cell>
              );
            })}
          </Table.Row>
          <Table.Row verticalAlign="top">
            <Table.Cell>
              Factor de variedad general
              <p>
                <strong>(refrescar solo cuando sea necesario)</strong>
              </p>
              <Button
                onClick={() => handleCalculateScheduleRatiosAll()}
                disabled={editable}
                loading={refreshingScheduleRatioAll}
                color="black"
                size="tiny"
                circular
                icon="redo"
              />
            </Table.Cell>
            {site?.playlists?.map((_, index) => {
              return (
                <Table.Cell key={index}>
                  {scheduleRatios[index] &&
                  Object.keys(scheduleRatios[index]).length ? (
                    <>
                      <p>
                        <span
                          style={{
                            color:
                              scheduleRatios[index].perResult < 3
                                ? 'red'
                                : 'green',
                          }}>
                          {scheduleRatios[index]?.perResult
                            ? `PER: ${scheduleRatios[index].perResult.toFixed(
                                2,
                              )} / `
                            : null}
                        </span>
                        <span
                          style={{
                            color:
                              scheduleRatios[index].extResult < 3
                                ? 'red'
                                : 'green',
                          }}>
                          {scheduleRatios[index]?.extResult
                            ? `EXT: ${scheduleRatios[index].extResult.toFixed(
                                2,
                              )}`
                            : null}
                        </span>
                      </p>
                      <p>{formatDate(scheduleRatios[index].calcDate)}</p>
                    </>
                  ) : null}
                  <Button
                    onClick={() =>
                      handleCalculateScheduleRatios(_.scheduleAlias, index)
                    }
                    loading={refreshingScheduleRatioArray[index]}
                    disabled={editable}
                    color="black"
                    size="tiny"
                    circular
                    icon="redo"
                  />
                </Table.Cell>
              );
            })}
          </Table.Row>
          <Table.Row>
            <Table.Cell>Bloqueadas</Table.Cell>
            <Table.Cell colSpan={cols}>
              {!editable ? (
                site?.bannedURIs ? (
                  site.bannedURIs.map((uri) => (
                    <div key={uri}>
                      <a href={uri} target="_blank">
                        {uri}
                      </a>
                      <br></br>
                    </div>
                  ))
                ) : (
                  ''
                )
              ) : (
                <Input
                  fluid
                  size="small"
                  placeholder="uri1,uri2,..."
                  value={bannedURIs}
                  onChange={(e) => setBannedURIs(e.target.value)}
                />
              )}
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    );
  };

  const handleCancelEdit = async () => {
    setCheckingEditable(true);
    const userToken = localStorage.getItem('token');
    const headers = { 'x-access-token': userToken };
    try {
      await aspbackend.put(
        '/globals',
        {
          adminIsLocked: false,
        },
        { headers },
      );
    } catch (error) {
      console.log(error);
    }
    setEditable(false);
    setSaveable(true);
    setErrorMsg('');
    setSite(originalSite);
    setAliasValues(originalSite.playlists.map((p) => p.scheduleAlias));
    setRatioValues(originalSite.playlists.map((p) => p.ratio));
    setHoursValues(originalSite.playlists.map((p) => p.hours));
    setSpotifyIDvalues(originalSite.playlists.map((p) => p.spotifyID));
    setSoundtrackIDvalues(originalSite.playlists.map((p) => p.soundtrackID));
    setFeaturesArray(originalSite.playlists.map((p) => p.audioFeatures));
    setPerWeightsArray(originalSite.playlists.map((p) => p.perWeights));
    setExtWeightsArray(originalSite.playlists.map((p) => p.extWeights));
    setBannedURIs(originalSite.bannedURIs?.join(','));
    setCols(originalSite.playlists.length);
    setCheckingEditable(false);
  };

  const handleSaveEdit = async () => {
    setCheckingEditable(true);
    setSite({ ...site, bannedURIs: bannedURIs.split(',') });
    const newPlaylists = site.playlists.map((playlist, i) => {
      playlist.scheduleAlias = aliasValues[i];
      playlist.ratio = parseFloat(ratioValues[i]);
      playlist.hours = parseFloat(hoursValues[i]);
      playlist.spotifyID = spotifyIDvalues[i];
      playlist.soundtrackID = soundtrackIDvalues[i];
      playlist.audioFeatures = featuresArray[i];
      playlist.perWeights = perWeightsArray[i];
      playlist.extWeights = extWeightsArray[i];
      return playlist;
    });
    setSite({ ...site, playlists: newPlaylists });
    setEditable(false);
    const userToken = localStorage.getItem('token');
    // Apply change to all sites in group
    if (site.playlistsGroup) {
      var affectedSites = sites.filter(
        (s) => s.playlistsGroup === site.playlistsGroup,
      );
    } else {
      affectedSites = [site];
    }
    for (const affectedSite of affectedSites) {
      await aspbackend.put(
        `/site/${affectedSite.siteID}`,
        {
          playlists: newPlaylists,
          bannedURIs: bannedURIs.split(','),
        },
        { headers: { 'x-access-token': userToken } },
      );
    }

    setOriginalSite({ ...site, bannedURIs: bannedURIs.split(',') });
    _site = site;
    localStorage.setItem(
      'site',
      JSON.stringify({ ...site, bannedURIs: bannedURIs.split(',') }),
    );
    await getSites();
    const headers = { 'x-access-token': userToken };
    await aspbackend.put(
      '/globals',
      {
        adminIsLocked: false,
      },
      { headers },
    );
    setCheckingEditable(false);
  };

  const handleRefreshPL = async (soundtrack, getUniverse) => {
    setRefreshingPlaylist(true);
    const userToken = localStorage.getItem('token');
    await aspbackend.post(
      `/site/${
        site.siteID
      }/refreshPlaylists?soundtrack=${soundtrack}&scheduleAlias=${refreshableScheduleAlias}${
        getUniverse === 1 ? '&getUniverse=1' : '&getUniverse=0'
      }`,
      {},
      { headers: { 'x-access-token': userToken } },
    );
    setRefreshingPlaylist(false);
    setRefreshModalVisible(false);
  };

  const handleCalculateScheduleRatios = async (scheduleAlias, index) => {
    setRefreshingScheduleRatioArray(
      refreshingScheduleRatioArray.map((r, i) => {
        if (i === index) {
          return true;
        } else {
          return r;
        }
      }),
    );
    const userToken = localStorage.getItem('token');
    const newRatiosData = await aspbackend.post(
      `/site/${site.siteID}/getScheduleRatios?scheduleAlias=${scheduleAlias}`,
      {},
      { headers: { 'x-access-token': userToken } },
    );
    const newRatios = newRatiosData.data;
    setScheduleRatios(
      site.playlists.map((p, i) => {
        if (i === index) {
          return newRatios;
        } else {
          return p.scheduleRatios;
        }
      }),
    );
    const newSite = {
      ...site,
      playlists: site.playlists.map((p, i) => {
        if (i === index) {
          return { ...p, scheduleRatios: newRatios };
        } else {
          return p;
        }
      }),
    };
    setSite(newSite);
    setOriginalSite(newSite);
    localStorage.setItem('site', JSON.stringify(newSite));
    setRefreshingScheduleRatioArray(
      refreshingScheduleRatioArray.map((r, i) => {
        if (i === index) {
          return false;
        } else {
          return r;
        }
      }),
    );
  };

  const handleCalculateScheduleRatiosAll = async () => {
    setRefreshingScheduleRatioAll(true);
    setRefreshingScheduleRatioArray(
      refreshingScheduleRatioArray.map((o) => true),
    );
    const userToken = localStorage.getItem('token');
    const newPlaylistsArrayData = await aspbackend.post(
      `/site/${site.siteID}/getSiteRatios`,
      {},
      { headers: { 'x-access-token': userToken } },
    );
    const newPlaylistsArray = newPlaylistsArrayData.data;
    setScheduleRatios(newPlaylistsArray.map((p) => p.scheduleRatios));
    const newSite = {
      ...site,
      playlists: newPlaylistsArray,
    };
    setSite(newSite);
    setOriginalSite(newSite);
    localStorage.setItem('site', JSON.stringify(newSite));
    setRefreshingScheduleRatioAll(false);
    setRefreshingScheduleRatioArray(
      refreshingScheduleRatioArray.map((o) => false),
    );
  };

  const refreshModal = () => {
    return (
      <div className="refreshModal">
        <p>
          Por refrescar "{refreshableScheduleAlias}" del sitio {site.siteID}
        </p>
        <Button
          disabled={
            spotifyIDvalues[refreshableScheduleIndex] === '' ? true : false
          }
          size="tiny"
          onClick={() => handleRefreshPL(false, 0)}
          secondary
          loading={refreshingPlaylist}
          style={{ backgroundColor: '#1DB954' }}>
          Solo Spotify
        </Button>
        <Button
          disabled={
            soundtrackIDvalues[refreshableScheduleIndex] === '' ? true : false
          }
          size="tiny"
          onClick={() => handleRefreshPL(true, 0)}
          secondary
          loading={refreshingPlaylist}
          style={{ backgroundColor: '#f23440' }}>
          Spotify y Soundtrack
        </Button>
        <Button
          disabled={
            spotifyIDvalues[refreshableScheduleIndex] === '' ? true : false
          }
          size="tiny"
          onClick={() => handleRefreshPL(false, 1)}
          secondary
          loading={refreshingPlaylist}
          style={{ backgroundColor: '#3c3c3c', color: 'white' }}>
          Probar UNIVERSO!
        </Button>
      </div>
    );
  };

  const getGroupSiteNames = (groupName) => {
    return (
      <span>
        {sites
          ?.filter((s) => s.playlistsGroup === groupName)
          .map((s) => s.siteDetails.name)
          .join(' / ')}
      </span>
    );
  };

  const logout = async () => {
    setErrorMsg('la sesión ha expirado');
    await new Promise((resolve) => setTimeout(resolve, 2000));
    setUser(null);
    setSite(null);
    localStorage.clear();
    setErrorMsg('');
    navigate(`/`);
  };

  const checkTokenAndsetEditable = async () => {
    setCheckingEditable(true);
    const userToken = localStorage.getItem('token');
    const headers = { 'x-access-token': userToken };
    await aspbackend
      .get('/checkToken', { headers })
      .then(async (response) => {
        if (response?.status === 200) {
          const globals = await aspbackend.get('globals', { headers });
          if (globals.data.adminIsLocked === false) {
            // update site
            const newSite = await aspbackend.get(`site/${site.siteID}`);
            setSite(newSite.data);
            setOriginalSite(newSite.data);
            localStorage.setItem('site', JSON.stringify(newSite.data));
            // lock admin
            await aspbackend.put(
              '/globals',
              {
                adminIsLocked: true,
                tokenInSession: userToken,
              },
              { headers },
            );
            setCheckingEditable(false);
            setErrorMsg('');
            setEditable(true);
          } else {
            // validate the locking token
            const lockingToken = globals.data.tokenInSession;
            await aspbackend
              .get('checkToken', {
                headers: { 'x-access-token': lockingToken },
              })
              .then(async (response) => {
                setErrorMsg('La sesión está tomada en estos momentos.');
                setCheckingEditable(false);
              })
              .catch(async () => {
                // lock admin
                await aspbackend.put(
                  '/globals',
                  {
                    adminIsLocked: true,
                    tokenInSession: userToken,
                  },
                  { headers },
                );
                setCheckingEditable(false);
                setErrorMsg('');
                setEditable(true);
              });
          }
        } else {
        }
      })
      .catch((e) => logout());
  };

  const checkTokenAndSave = async () => {
    const userToken = localStorage.getItem('token');
    await aspbackend
      .get('/checkToken', { headers: { 'x-access-token': userToken } })
      .then(async (response) => {
        if (response?.status === 200) {
          handleSaveEdit();
        } else {
        }
      })
      .catch((e) => logout());
  };

  const mainButtons = (location) => {
    return !editable ? (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-end',
        }}>
        <Button
          size="tiny"
          disabled={!playlistsASP.length}
          loading={checkingEditable}
          onClick={checkTokenAndsetEditable}
          secondary>
          Editar
        </Button>
        {errorMsg ? (
          <p style={{ fontSize: '1rem', color: 'red' }}>{errorMsg}</p>
        ) : null}
      </div>
    ) : (
      <div key={location}>
        <Button
          size="tiny"
          onClick={handleNewCol}
          style={{ backgroundColor: '#030027', color: 'white' }}>
          Nueva lista
        </Button>
        <Button
          size="tiny"
          disabled={!saveable}
          loading={checkingEditable}
          onClick={checkTokenAndSave}
          style={{ backgroundColor: '#C4FB15', color: 'black' }}>
          Grabar
        </Button>
        <Button
          size="tiny"
          onClick={handleCancelEdit}
          style={{ backgroundColor: '#FF092F', color: 'white' }}
          loading={checkingEditable}>
          Cancelar
        </Button>
        {errorMsg ? (
          <p style={{ fontSize: '1rem', color: 'red' }}>{errorMsg}</p>
        ) : null}
      </div>
    );
  };

  return (
    <div className="adminDiv">
      {site && site.siteID !== '0' ? <NowPlayingAdminMini site={site} /> : null}
      {site && sites ? siteSelector() : null}
      {site && site.siteID !== '0' ? (
        <>
          <div className="titleDiv">
            <p>
              Listas escogidas en{' '}
              <strong>
                {!site?.playlistsGroup
                  ? site?.siteDetails?.name
                  : getGroupSiteNames(site.playlistsGroup)}
              </strong>
            </p>
            {mainButtons('top')}
          </div>
          {site && site.playlists && playlistsASP
            ? renderPlaylists(playlistsASP)
            : null}
          {site?.sourceType === 'spotify' ? (
            <SpotifyWebPlayer site={_site} />
          ) : null}
          {refreshModalVisible ? refreshModal() : null}
          {site && site.playlists ? advancedModal(site.playlists) : null}
          {mainButtons('bottom')}
        </>
      ) : (
        <GlobalSitesView sites={sites} />
      )}
    </div>
  );
};

export default Admin;
