import { useContext, useEffect, useState } from 'react';
import { Form } from 'semantic-ui-react';
import { useNavigate } from 'react-router-dom';
import { BsFillArrowRightCircleFill } from 'react-icons/bs';
import aspbackend from '../api/aspbackend';
import '../assets/styles/Login.css';
import { Context as GeneralContext } from '../context/GeneralContext';
import 'react-activity/dist/Dots.css';
import Footer from './Footer';

const Login = () => {
  const { setUser, setTokenValidity } = useContext(GeneralContext);

  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [username, setUsername] = useState('');
  const [emailOrUsername, setEmailOrUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const validateTokenAndRedirect = async (token) => {
    await aspbackend
      .get('/checkToken', { headers: { 'x-access-token': token } })
      .then(async (response) => {
        setTokenValidity(true);
        const newUser = response.data;
        setUser(newUser);
        if (newUser.artist) {
          const artistName = newUser.artist;
          navigate(`artist/${artistName}/admin`, {
            state: {
              artistName: artistName,
            },
          });
        } else if (newUser.role === 'admin') {
          await aspbackend.get(`sites/`).then((response) => {
            navigate(`/admin`, {
              state: {
                sites: response.data,
              },
            });
          });
        } else if (response.data.sites.length) {
          const firstSite = response.data.sites[0];
          await aspbackend.get(`site/${firstSite}`).then((response) => {
            navigate(`sitios/${firstSite}/admin`, {
              state: {
                site: response.data,
              },
            });
          });
        }
      })
      .catch(async (e) => {
        if (e?.response?.data === 'Token inválido') {
          setTokenValidity(false);
        }
      });
  };

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      validateTokenAndRedirect(token);
    }
  }, []);

  const handleLogin = async () => {
    await aspbackend
      .post('/login', {
        email: email ? email.trim() : null,
        username: username ? username.trim() : null,
        password: password.trim(),
      })
      .then(async (response) => {
        if (response.data.token) {
          setError('');
          const newUser = response.data;
          setUser(newUser);
          localStorage.setItem('user', JSON.stringify(newUser));
          localStorage.setItem('token', newUser.token);
          // navigate!
          if (newUser.artist) {
            const artistName = newUser.artist;
            navigate(`artist/${artistName}/admin`, {
              state: {
                artistName: artistName,
              },
            });
          } else if (newUser.role === 'admin') {
            await aspbackend.get(`sites/`).then((response) => {
              navigate(`/admin`, {
                state: {
                  sites: response.data,
                },
              });
            });
          } else if (response.data.sites.length) {
            const firstSite = response.data.sites[0];
            await aspbackend.get(`site/${firstSite}`).then((response) => {
              navigate(`sitios/${firstSite}/admin`, {
                state: {
                  site: response.data,
                },
              });
            });
          }
        }
      })
      .catch((e) => {
        setError(e.response ? e.response.data : e.message);
      });
  };

  const handleKeyPress = (e) => {
    if (e.charCode === 32 || e.charCode === 13) {
      // Prevent the default action to stop scrolling when space is pressed
      e.preventDefault();
    }
  };

  const handleEmailUserChange = (event, { value }) => {
    setEmailOrUsername(value);
    if (value.includes('@')) {
      setEmail(value);
    } else {
      setUsername(value);
    }
  };

  const handlePasswordChange = (event, { value }) => {
    setPassword(value);
  };

  return (
    <>
      <div className="loginPageDiv">
        <div className="introLoginDiv">
          <p className="playlistsTitle">
            Queremos que más música peruana suene por todos lados.
          </p>
        </div>
        <Footer />
      </div>
      <div className="loginDiv">
        <Form>
          <Form.Group widths="equal">
            <div className="loginText">
              <p>¿Músico/Admin? ingresa:</p>
            </div>
            <Form.Input
              fluid
              placeholder="Correo electrónico o usuario"
              value={emailOrUsername}
              onChange={handleEmailUserChange}
            />
            <Form.Input
              fluid
              placeholder="Contraseña"
              type="password"
              value={password}
              onChange={handlePasswordChange}
            />
            <Form.Button
              color="black"
              size={'large'}
              onClick={handleLogin}
              onKeyPress={handleKeyPress}>
              <BsFillArrowRightCircleFill />
            </Form.Button>
          </Form.Group>
        </Form>
        {error ? (
          <p style={{ marginTop: '1rem', color: 'white' }}>{error}</p>
        ) : null}
      </div>
    </>
  );
};

export default Login;
