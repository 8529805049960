import { useContext, useEffect, useMemo, useState } from 'react';
import { Context as GeneralContext } from '../context/GeneralContext';
import '../assets/styles/TracksTable.css';
import { Table, Column } from 'react-virtualized';
import { Input, Loader } from 'semantic-ui-react';
import 'react-virtualized/styles.css'; // only needs to be imported once

const TracksTable = () => {
  const [sortConfig, setSortConfig] = useState(null);
  const [loading, setLoading] = useState(false);
  const [titleSearch, setTitleSearch] = useState('');
  const [filteredTracks, setFilteredTracks] = useState(null);

  const {
    getTracks,
    state: { tracks },
  } = useContext(GeneralContext);

  useEffect(() => {
    const localGetTracks = async () => {
      setLoading(true);
      await getTracks();
      setFilteredTracks(tracks);
      setLoading(false);
    };
    if (!tracks?.length) {
      localGetTracks();
    }
  }, []);

  useEffect(() => {
    if (titleSearch?.length > 2) {
      const term = normalizeTerm(titleSearch);
      setFilteredTracks(
        tracks?.filter(
          (t) =>
            normalizeTerm(t.title)?.includes(term) ||
            normalizeTerm(t.artists)?.includes(term) ||
            normalizeTerm(t.playlist)?.includes(term),
        ),
      );
    } else {
      setFilteredTracks(tracks);
    }
  }, [titleSearch, tracks]);

  const normalizeTerm = (string) => {
    return string
      ?.toLowerCase()
      .normalize('NFD')
      .replace(/\p{Diacritic}/gu, '');
  };

  useMemo(() => {
    let sortedTracks = filteredTracks;
    if (sortConfig !== null) {
      sortedTracks.sort((a, b) => {
        if (a[sortConfig.sortBy] < b[sortConfig.sortBy]) {
          return sortConfig.direction === 'ASC' ? -1 : 1;
        }
        if (a[sortConfig.sortBy] > b[sortConfig.sortBy]) {
          return sortConfig.direction === 'ASC' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortedTracks;
  }, [tracks, sortConfig]);

  const renderTracks = () => {
    const requestSort = ({ sortBy }) => {
      let direction = 'ASC';
      if (sortConfig?.sortBy === sortBy && sortConfig?.direction === 'ASC') {
        direction = 'DESC';
      }
      setSortConfig({ sortBy, direction });
    };

    const getClassNamesFor = (name) => {
      if (!sortConfig) {
        return;
      }
      return sortConfig.key === name ? sortConfig.direction : undefined;
    };

    const textSearchInput = (placeholder, term, onChange) => {
      return (
        <Input
          placeholder={placeholder}
          value={term}
          onChange={onChange}></Input>
      );
    };

    return (
      <>
        <h4>Canciones: {filteredTracks?.length}</h4>
        {textSearchInput('canción, artista o playlist', titleSearch, (e) =>
          setTitleSearch(e.target.value),
        )}
        {filteredTracks?.length ? (
          <Table
            width={1200}
            height={1000}
            headerHeight={50}
            rowHeight={30}
            sort={requestSort}
            sortBy={sortConfig?.sortBy}
            sortDirection={sortConfig?.direction}
            headerStyle={{ fontSize: '0.8rem' }}
            gridStyle={{ fontSize: '0.8rem' }}
            rowCount={filteredTracks?.length || 100}
            rowGetter={({ index }) =>
              filteredTracks ? filteredTracks[index] : 1
            }>
            <Column label="SpotifyID" dataKey="id" width={200} />
            <Column label="Title" dataKey="title" width={150} />
            <Column label="Artists" dataKey="artists" width={150} />
            <Column label="Playlist" dataKey="playlist" width={100} />
            <Column label="Popularity" dataKey="popularity" width={60} />
            <Column label="Tempo" dataKey="tempo" width={60} />
            <Column label="Energy" dataKey="energy" width={60} />
            <Column label="Danceability" dataKey="danceability" width={60} />
            <Column label="Valence" dataKey="valence" width={60} />
            <Column label="Loudness" dataKey="loudness" width={60} />
            <Column label="Speechiness" dataKey="speechiness" width={60} />
            <Column label="Acousticness" dataKey="acousticness" width={60} />
            <Column
              label="Instrumentalness"
              dataKey="instrumentalness"
              width={60}
            />
            <Column label="Liveness" dataKey="liveness" width={60} />
            <Column label="Mode" dataKey="mode" width={60} />
            <Column label="Key" dataKey="key" width={60} />
          </Table>
        ) : null}
      </>
    );
  };

  return (
    <>
      <div>{!loading ? renderTracks() : 'Cargando canciones...'}</div>
      <Loader active={loading} inline />
    </>
  );
};

export default TracksTable;
