import React, { useState } from 'react';
import { Button, Checkbox } from 'semantic-ui-react';

const yearRangesArrayToObject = (array) => {
  let yearObject = {
    fifties: false,
    sixties: false,
    seventies: false,
    eighties: false,
    nineties: false,
    naughties: false,
    tens: false,
    twenties: false,
  };
  array.length > 0 &&
    array.map((years) => {
      if (years[0] === 1950) {
        yearObject.fifties = true;
      }
      if (years[0] === 1960) {
        yearObject.sixties = true;
      }
      if (years[0] === 1970) {
        yearObject.seventies = true;
      }
      if (years[0] === 1980) {
        yearObject.eighties = true;
      }
      if (years[0] === 1990) {
        yearObject.nineties = true;
      }
      if (years[0] === 2000) {
        yearObject.naughties = true;
      }
      if (years[0] === 2010) {
        yearObject.tens = true;
      }
      if (years[0] === 2020) {
        yearObject.twenties = true;
      }
    });
  return yearObject;
};

const DecadeSelector = ({
  text,
  initialValues,
  handleParentValue,
  featureName,
  featureActive,
  listGroup,
}) => {
  const [active, setActive] = useState(featureActive);
  const [value, setValue] = useState(yearRangesArrayToObject(initialValues));

  const handleClick = (clickedDecade) => {
    const newValue = { ...value, [clickedDecade]: !value[clickedDecade] };
    let decadesArray = [];
    newValue.fifties
      ? decadesArray.push([1950, 1960])
      : (decadesArray = decadesArray.filter((e) => e[0] !== 1950));
    newValue.sixties
      ? decadesArray.push([1960, 1970])
      : (decadesArray = decadesArray.filter((e) => e[0] !== 1960));
    newValue.seventies
      ? decadesArray.push([1970, 1980])
      : (decadesArray = decadesArray.filter((e) => e[0] !== 1970));
    newValue.eighties
      ? decadesArray.push([1980, 1990])
      : (decadesArray = decadesArray.filter((e) => e[0] !== 1980));
    newValue.nineties
      ? decadesArray.push([1990, 2000])
      : (decadesArray = decadesArray.filter((e) => e[0] !== 1990));
    newValue.naughties
      ? decadesArray.push([2000, 2010])
      : (decadesArray = decadesArray.filter((e) => e[0] !== 2000));
    newValue.tens
      ? decadesArray.push([2010, 2020])
      : (decadesArray = decadesArray.filter((e) => e[0] !== 2010));
    newValue.twenties
      ? decadesArray.push([2020, 2030])
      : (decadesArray = decadesArray.filter((e) => e[0] !== 2020));
    handleParentValue(featureName, decadesArray, listGroup);
    setValue(newValue);
  };

  return (
    <div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          marginBottom: '0.5rem',
        }}>
        <Checkbox
          toggle
          checked={active}
          onClick={() => {
            setActive((m) => !m);
            if (active) {
              handleParentValue(featureName, [], listGroup);
            }
          }}
        />
        <h4 style={{ margin: '0 1rem' }}>
          {'  '} {text}
        </h4>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
        }}>
        <Button
          toggle
          disabled={!active}
          active={value['fifties']}
          onClick={() => handleClick('fifties')}>
          1950s
        </Button>
        <Button
          toggle
          disabled={!active}
          active={value['sixties']}
          onClick={() => handleClick('sixties')}>
          1960s
        </Button>
        <Button
          toggle
          disabled={!active}
          active={value['seventies']}
          onClick={() => handleClick('seventies')}>
          1970s
        </Button>
        <Button
          toggle
          disabled={!active}
          active={value['eighties']}
          onClick={() => handleClick('eighties')}>
          1980s
        </Button>
        <Button
          toggle
          disabled={!active}
          active={value['nineties']}
          onClick={() => handleClick('nineties')}>
          1990s
        </Button>
        <Button
          toggle
          disabled={!active}
          active={value['naughties']}
          onClick={() => handleClick('naughties')}>
          2000s
        </Button>
        <Button
          toggle
          disabled={!active}
          active={value['tens']}
          onClick={() => handleClick('tens')}>
          2010s
        </Button>
        <Button
          toggle
          disabled={!active}
          active={value['twenties']}
          onClick={() => handleClick('twenties')}>
          2020s
        </Button>
      </div>
    </div>
  );
};

export default DecadeSelector;
