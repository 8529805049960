import React, { useState, useEffect } from 'react';
import aspbackend from '../api/aspbackend';
import { Button, Checkbox, Input, Modal, Tab } from 'semantic-ui-react';
import DoubleBar from './DoubleBar';
import CheckSelector from './CheckSelector';
import DecadeSelector from './DecadeSelector';

const SingleFiltersModal = ({
  modalParams,
  openAdvancedModal,
  featuresObject,
  setOpenAdvancedModal,
  handleNewFeatures,
}) => {
  const [newFeatures, setNewFeatures] = useState(featuresObject);

  const handleFeaturesObject = (feature, value) => {
    setNewFeatures({ ...newFeatures, [feature]: value });
  };

  const handleCloseAdvancedModal = () => {
    setOpenAdvancedModal(false);
    handleNewFeatures(modalParams.type, modalParams.pid, newFeatures);
  };

  const modalContent = (features) => {
    return (
      <Modal.Content>
        <DoubleBar
          text={'Tempo'}
          initialMinValue={features?.tempo ? features?.tempo[0] : 10}
          initialMaxValue={features?.tempo ? features?.tempo[1] : 220}
          featureActive={features?.tempo?.length > 0}
          min={10}
          max={220}
          step={1}
          handleParentValue={handleFeaturesObject}
          featureName={'tempo'}
        />
        <DoubleBar
          text={'Bailabilidad'}
          initialMinValue={
            features?.danceability ? features?.danceability[0] : 0
          }
          initialMaxValue={
            features?.danceability ? features?.danceability[1] : 1
          }
          featureActive={features?.danceability?.length > 0}
          min={0}
          max={1}
          step={0.01}
          handleParentValue={handleFeaturesObject}
          featureName={'danceability'}
        />
        <DoubleBar
          text={'Energía'}
          initialMinValue={features?.energy ? features?.energy[0] : 0}
          initialMaxValue={features?.energy ? features?.energy[1] : 1}
          featureActive={features?.energy?.length > 0}
          min={0}
          max={1}
          step={0.01}
          handleParentValue={handleFeaturesObject}
          featureName={'energy'}
        />
        <DoubleBar
          text={'Alegría'}
          initialMinValue={features?.valence ? features?.valence[0] : 0}
          initialMaxValue={features?.valence ? features?.valence[1] : 1}
          featureActive={features?.valence?.length > 0}
          min={0}
          max={1}
          step={0.01}
          handleParentValue={handleFeaturesObject}
          featureName={'valence'}
        />
        <DoubleBar
          text={'Nivel instrumental'}
          initialMinValue={
            features?.instrumentalness ? features?.instrumentalness[0] : 0
          }
          initialMaxValue={
            features?.instrumentalness ? features?.instrumentalness[1] : 1
          }
          featureActive={features?.instrumentalness?.length > 0}
          min={0}
          max={1}
          step={0.00001}
          handleParentValue={handleFeaturesObject}
          featureName={'instrumentalness'}
        />
        <DoubleBar
          text={'Nivel acústico'}
          initialMinValue={
            features?.acousticness ? features?.acousticness[0] : 0
          }
          initialMaxValue={
            features?.acousticness ? features?.acousticness[1] : 1
          }
          featureActive={features?.acousticness?.length > 0}
          min={0}
          max={1}
          step={0.001}
          handleParentValue={handleFeaturesObject}
          featureName={'acousticness'}
        />
        <DoubleBar
          text={'Presencia de voz'}
          initialMinValue={features?.speechiness ? features?.speechiness[0] : 0}
          initialMaxValue={features?.speechiness ? features?.speechiness[1] : 1}
          featureActive={features?.speechiness?.length > 0}
          min={0}
          max={1}
          step={0.001}
          handleParentValue={handleFeaturesObject}
          featureName={'speechiness'}
        />
        <DoubleBar
          text={'Amplitud en dB'}
          initialMinValue={features?.loudness ? features?.loudness[0] : -30}
          initialMaxValue={features?.loudness ? features?.loudness[1] : 0}
          featureActive={features?.loudness?.length > 0}
          min={-30}
          max={0}
          step={0.001}
          handleParentValue={handleFeaturesObject}
          featureName={'loudness'}
        />
        <DoubleBar
          text={'Popularidad'}
          initialMinValue={features?.popularity ? features?.popularity[0] : 0}
          initialMaxValue={features?.popularity ? features?.popularity[1] : 100}
          featureActive={features?.popularity?.length > 0}
          min={0}
          max={100}
          step={1}
          handleParentValue={handleFeaturesObject}
          featureName={'popularity'}
        />
        <CheckSelector
          text={'Habilitar explícitas'}
          featureActive={features?.enableExplicit}
          handleParentValue={handleFeaturesObject}
          featureName={'enableExplicit'}
        />
        <DecadeSelector
          text={'Décadas'}
          initialValues={features?.yearRanges ? features?.yearRanges : []}
          featureActive={features?.yearRanges?.length > 0}
          handleParentValue={handleFeaturesObject}
          featureName={'yearRanges'}
        />
      </Modal.Content>
    );
  };

  return (
    <>
      <Modal open={openAdvancedModal}>
        <Modal.Header>{`Filtros para ${
          modalParams.pname || modalParams.type
        }`}</Modal.Header>
        {modalContent(newFeatures)}
        <Modal.Actions>
          <Button
            content="Regresar"
            labelPosition="right"
            icon="checkmark"
            // disabled={checkWeightSums()}
            onClick={() => handleCloseAdvancedModal()}
            positive
          />
        </Modal.Actions>
      </Modal>
    </>
  );
};

export default SingleFiltersModal;
