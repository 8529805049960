import React, { useContext } from 'react';
import { Button, Dropdown, Input } from 'semantic-ui-react';
import { Context as SpotifyContext } from '../context/SpotifyContext';
import { Context as GeneralContext } from '../context/GeneralContext';
import aspbackend from '../api/aspbackend';
import '../assets/styles/App.css';

const PlaylistsMenu = ({
  spotify,
  handleLoading,
  loading,
  resetPlaylistOffset,
  site,
  userToken,
}) => {
  const {
    getPlaylistsUser,
    setActivePlaylist,
    state: { playlistsPER, playlistsUser },
  } = useContext(SpotifyContext);
  const { setSite } = useContext(GeneralContext);

  const optionsEXT = playlistsUser
    .filter((item) => item.name.includes('EXT') || item.name.includes('LAT'))
    .map((item) => {
      return { text: item.name, value: item.id };
    });

  const optionsPER = playlistsPER
    .filter((item) => item.name.includes('PER') || item.name.includes('MIX'))
    .map((item) => {
      return { text: item.name, value: item.id };
    });

  const initialPlaylistPER = localStorage.getItem('playlistPER')
    ? JSON.parse(localStorage.getItem('playlistPER'))
    : '';

  const initialPlaylistEXT = localStorage.getItem('playlistEXT')
    ? JSON.parse(localStorage.getItem('playlistEXT'))
    : '';

  const [listPER, setListPER] = React.useState(initialPlaylistPER.id);
  const [listEXT, setListEXT] = React.useState(initialPlaylistEXT.id);
  const [clientName, setClientName] = React.useState(site?.siteDetails?.name);
  const [listPERName, setListPERName] = React.useState();
  const [listEXTName, setListEXTName] = React.useState();
  const [isError, setIsError] = React.useState('');

  const handleChangePER = (event, { value }) => {
    setListPER(value);
    setListPERName(getPlaylistNameFromID(optionsPER, value));
  };

  const handleChangeEXT = (event, { value }) => {
    setListEXT(value);
    setListEXTName(getPlaylistNameFromID(optionsEXT, value));
  };

  const handleClientNameChange = (event, { value }) => {
    setClientName(value);
  };

  const paginate = (items, page = 1, perPage = 100) =>
    items.slice(perPage * (page - 1), perPage * page);

  const getPlaylistNameFromID = (options, id) => {
    const filteredOptions = options.filter((option) => option.value === id);
    return filteredOptions[0]?.text;
  };

  const mixPlaylists = async () => {
    setIsError('');
    // Hide player
    handleLoading(true);
    // Playlist operations
    const userId = await spotify.getMe();

    // Get playlists
    try {
      var playlistEXT = await getCompletePlaylist(listEXT);
    } catch {
      playlistEXT = { items: [] };
    }
    try {
      var playlistPER = await getCompletePlaylist(listPER);
    } catch {
      playlistPER = { items: [] };
    }

    // Error handling
    if (!playlistPER.length) {
      setIsError('Lista peruana debe ser definida y contener canciones');
      handleLoading(false);
      return;
    }
    if (playlistPER.length < playlistEXT.length) {
      setIsError('Lista extranjera no debe tener más canciones que la peruana');
      handleLoading(false);
      return;
    }

    const playlistPERURIs = playlistPER.map((item) => item.track.uri);
    const playlistEXTURIs = playlistEXT.length
      ? playlistEXT.map((item) => item.track.uri)
      : [];
    const newPlaylistURIs = mixiMix(playlistPERURIs, playlistEXTURIs); // MIXI MIX!!!!

    const newPlaylistName = `MIX - ${clientName}`;
    const newPlaylistExists = playlistsUser.filter(
      (playlist) => playlist.name === newPlaylistName,
    );

    var playlistToCreate = {};
    if (!newPlaylistExists.length) {
      playlistToCreate = await spotify.createPlaylist(userId['id'], {
        name: newPlaylistName,
        description: `Creada por AquíSuenaPerú`,
      });
      getPlaylistsUser();
    } else {
      playlistToCreate = newPlaylistExists[0];
    }

    // Add items to Playlist
    if (newPlaylistURIs.length > 100) {
      await spotify.replaceTracksInPlaylist(
        playlistToCreate.id,
        paginate(newPlaylistURIs, 1),
      );
      const pages = Math.ceil(newPlaylistURIs.length / 100);

      for (let i = 1; i < pages; i++) {
        await spotify.addTracksToPlaylist(
          playlistToCreate.id,
          paginate(newPlaylistURIs, i + 1),
        );
      }
    } else {
      await spotify.replaceTracksInPlaylist(
        playlistToCreate.id,
        newPlaylistURIs,
      );
    }
    // Set final playlist, update states and show player
    resetPlaylistOffset();
    setActivePlaylist(playlistToCreate);
    localStorage.setItem('activePlaylist', JSON.stringify(playlistToCreate));
    localStorage.setItem('playlistPER', JSON.stringify(playlistPER));
    localStorage.setItem('playlistEXT', JSON.stringify(playlistEXT));
    localStorage.setItem('newPlaylistURIs', JSON.stringify(newPlaylistURIs));
    localStorage.removeItem('currentURI');
    // store new playlist URI & details in backend
    await aspbackend.put(
      `/site/${site.siteID}`,
      {
        existingPlaylist: playlistToCreate,
        newPlaylistURIs: newPlaylistURIs,
        playlistPERName: listPERName,
        playlistEXTName: listEXTName ? listEXTName : null,
      },
      { headers: { 'x-access-token': userToken } },
    );
    setSite({
      ...site,
      existingPlaylist: playlistToCreate,
      playlistPERName: listPERName,
      playlistEXTName: listEXTName ? listEXTName : null,
    });
    handleLoading(false);
  };

  const mixiMix = (arr1, arr2) => {
    const shuffledArr1 = arr1.sort((a, b) => 0.5 - Math.random());
    const shuffledArr2 = arr2.sort((a, b) => 0.5 - Math.random());
    const len1 = arr1.length;
    const len2 = arr2.length;
    const ratio = Math.round(len1 / len2);
    const newArr = [];
    let j = 0;
    for (let i = 0; i < len1; i++) {
      if (i % ratio !== 0 || (i === 0 && ratio !== 1)) {
        newArr.push(shuffledArr1[i]);
      } else {
        if (shuffledArr1[i]) {
          newArr.push(shuffledArr1[i]);
        }
        if (shuffledArr2[j]) {
          newArr.push(shuffledArr2[j]);
        }
        j += 1;
      }
    }
    return newArr;
  };

  const getCompletePlaylist = async (playlistID) => {
    let batch_length = 100;
    let offset = 0;
    let finalPlaylist = [];
    let existing_tracks = [];
    while (batch_length !== 0) {
      const playlistBatch = await spotify.getPlaylistTracks(playlistID, {
        limit: 100,
        offset,
      });
      existing_tracks = playlistBatch['items'];
      batch_length = existing_tracks.length;
      if (batch_length > 0) {
        finalPlaylist.push(...existing_tracks);
      }
      offset += batch_length;
    }
    return finalPlaylist;
  };

  return (
    <div>
      <Input
        fluid
        label="Nombre corto de cliente"
        placeholder="Nombre corto de cliente"
        value={clientName}
        onChange={handleClientNameChange}
      />
      <Dropdown
        placeholder="Lista peruana"
        clearable
        selection
        options={optionsPER}
        value={listPER}
        onChange={handleChangePER}
        style={{
          margin: '1rem 0',
          display: 'block',
        }}
      />
      <Dropdown
        placeholder="Lista extranjera"
        clearable
        selection
        options={optionsEXT}
        value={listEXT}
        onChange={handleChangeEXT}
        style={{
          margin: '1rem 0',
          display: 'block',
        }}
      />
      <Button
        onClick={mixPlaylists}
        color="black"
        loading={loading}
        style={{
          margin: '1rem 0',
          display: 'block',
        }}>
        (Re-)Mezcla
      </Button>
      {isError ? <p style={{ color: 'tomato' }}>{isError}</p> : null}
    </div>
  );
};

export default PlaylistsMenu;
