import axios from 'axios';

const instance = axios.create({
  baseURL: ' https://api.soundtrackyourbrand.com/v2',
  headers: {
    'Content-type': 'application/json',
  },
});

export default instance;
