import React, {
  useState,
  forwardRef,
  useEffect,
  useImperativeHandle,
} from 'react';
import aspbackend from '../api/aspbackend';
import { Button, Checkbox, Input, Modal, Tab } from 'semantic-ui-react';
import DoubleBar from './DoubleBar';
import CheckSelector from './CheckSelector';
import DecadeSelector from './DecadeSelector';

const FiltersModal = forwardRef(
  (
    {
      site,
      openAdvancedModal,
      activeModalIndex,
      playlistsASP,
      setOpenAdvancedModal,
      handleParentFeaturesObject,
      handleParentWeightsObject,
      featuresArray,
      perWeightsArray,
      extWeightsArray,
    },
    ref,
  ) => {
    const handleCloseAdvancedModal = () => {
      // Remove list filters if list was removed
      let finalListFeatures = listFeaturesObjectsArray;
      const groupedIncludedIds = [...perIncludedLists, ...extIncludedLists].map(
        (pl) => pl.id,
      );
      finalListFeatures[activeModalIndex] &&
        Object.keys(finalListFeatures[activeModalIndex]).map((pid) => {
          if (!groupedIncludedIds.includes(pid)) {
            delete finalListFeatures[activeModalIndex][pid];
          }
        });
      // Return final features object to parent
      const finalFeaturesObject = {
        perFeatures: perFeaturesObjectsArray[activeModalIndex],
        extFeatures: extFeaturesObjectsArray[activeModalIndex],
        listFeatures: finalListFeatures[activeModalIndex],
      };

      handleParentFeaturesObject(finalFeaturesObject, activeModalIndex);
      handleParentWeightsObject(perWeights, extWeights, activeModalIndex);
      setOpenAdvancedModal(false);
    };

    const calcWeightsSum = (weightsObj) => {
      return Object.keys(weightsObj)?.reduce(
        (acc, key) => acc + weightsObj[key],
        0,
      );
    };

    const [perIncludedLists, setPerIncludedLists] = useState(
      playlistsASP &&
        playlistsASP.filter((p) =>
          site.playlists[activeModalIndex]?.perList.includes(p.id),
        ),
    );
    const [extIncludedLists, setExtIncludedLists] = useState(
      playlistsASP &&
        playlistsASP.filter((p) =>
          site.playlists[activeModalIndex]?.extList.includes(p.id),
        ),
    );
    const [perFeaturesObjectsArray, setPerFeaturesObjectsArray] = useState(
      featuresArray.map((f) => f?.perFeatures),
    );

    const [extFeaturesObjectsArray, setExtFeaturesObjectsArray] = useState(
      featuresArray.map((f) => f?.extFeatures),
    );

    const [listFeaturesObjectsArray, setListFeaturesObjectsArray] = useState(
      featuresArray.map((f) => f?.listFeatures),
    );
    const [perWeights, setPerWeights] = useState(
      perWeightsArray[activeModalIndex] &&
        Object.keys(perWeightsArray[activeModalIndex])?.length > 1
        ? perWeightsArray[activeModalIndex]
        : undefined,
    );
    const [perWeightsSum, setPerWeightsSum] = useState(
      perWeightsArray[activeModalIndex] &&
        Object.keys(perWeightsArray[activeModalIndex])?.length > 1
        ? calcWeightsSum(perWeightsArray[activeModalIndex])
        : 100,
    );

    const [extWeights, setExtWeights] = useState(
      extWeightsArray[activeModalIndex] &&
        Object.keys(extWeightsArray[activeModalIndex])?.length > 1
        ? extWeightsArray[activeModalIndex]
        : undefined,
    );
    const [extWeightsSum, setExtWeightsSum] = useState(
      extWeightsArray[activeModalIndex] &&
        Object.keys(extWeightsArray[activeModalIndex])?.length > 1
        ? calcWeightsSum(extWeightsArray[activeModalIndex])
        : 100,
    );

    const [enablePERWeights, setEnablePERWeights] = useState(
      perWeightsArray[activeModalIndex] &&
        Object.keys(perWeightsArray[activeModalIndex]).length > 1
        ? true
        : false,
    );
    const [enableEXTWeights, setEnableEXTWeights] = useState(
      extWeightsArray[activeModalIndex] &&
        Object.keys(extWeightsArray[activeModalIndex]).length > 1
        ? true
        : false,
    );

    const [listFactors, setListFactors] = useState({});
    const [refreshingListFactors, setRefreshingListFactors] = useState(false);

    const checkWeightSums = () => {
      return (
        (perWeights && perWeightsSum !== 100) ||
        (extWeights && extWeightsSum !== 100)
      );
    };

    const handleCalculateListFactors = async () => {
      const perList = perIncludedLists.map((p) => p.id);
      const extList = extIncludedLists.map((p) => p.id);
      setRefreshingListFactors(true);
      const userToken = localStorage.getItem('token');
      const newListFactorsResponse = await aspbackend.post(
        `/site/${site.siteID}/refreshPlaylists?soundtrack=false&scheduleAlias=${site.playlists[activeModalIndex].scheduleAlias}&getListFactors=1`,
        { perWeights, extWeights, perList, extList },
        { headers: { 'x-access-token': userToken } },
      );
      const newListFactors = newListFactorsResponse.data;
      setListFactors(newListFactors);
      setRefreshingListFactors(false);
    };

    useImperativeHandle(ref, () => ({
      cleanModalState() {
        setPerFeaturesObjectsArray([]);
        setExtFeaturesObjectsArray([]);
        setListFeaturesObjectsArray([]);
        setPerWeights(undefined);
        setExtWeights(undefined);
        setListFactors({});
      },
    }));

    useEffect(() => {
      setPerIncludedLists(
        playlistsASP &&
          playlistsASP.filter((p) =>
            site.playlists[activeModalIndex]?.perList.includes(p.id),
          ),
      );
      setExtIncludedLists(
        playlistsASP &&
          playlistsASP.filter((p) =>
            site.playlists[activeModalIndex]?.extList.includes(p.id),
          ),
      );
      setPerWeights(perWeightsArray[activeModalIndex]);
      setExtWeights(extWeightsArray[activeModalIndex]);
      setPerWeightsSum(
        calcWeightsSum(
          perWeights
            ? perWeights
            : perWeightsArray[activeModalIndex]
            ? perWeightsArray[activeModalIndex]
            : 100,
        ),
      );
      setExtWeightsSum(
        calcWeightsSum(
          extWeights
            ? extWeights
            : extWeightsArray[activeModalIndex]
            ? extWeightsArray[activeModalIndex]
            : 100,
        ),
      );
      setListFactors({});
      setEnablePERWeights(
        perWeightsArray[activeModalIndex] &&
          Object.keys(perWeightsArray[activeModalIndex])?.length > 1
          ? true
          : false,
      );
      setEnableEXTWeights(
        extWeightsArray[activeModalIndex] &&
          Object.keys(extWeightsArray[activeModalIndex])?.length > 1
          ? true
          : false,
      );
    }, [openAdvancedModal]);

    useEffect(() => {
      if (perWeights) {
        setPerWeightsSum(calcWeightsSum(perWeights));
      }
      if (extWeights) {
        setExtWeightsSum(calcWeightsSum(extWeights));
      }
    }, [perWeights, extWeights]);

    const handleFeaturesObject = (key, value, listGroup) => {
      if (listGroup === 'PER') {
        if (!Array.isArray(value) || value.length) {
          if (perFeaturesObjectsArray.length) {
            setPerFeaturesObjectsArray(
              perFeaturesObjectsArray.map((a, i) => {
                if (i === activeModalIndex) {
                  return {
                    ...perFeaturesObjectsArray[activeModalIndex],
                    [key]: value,
                  };
                } else {
                  return a;
                }
              }),
            );
          } else {
            const newObject = {
              ...perFeaturesObjectsArray[activeModalIndex],
              [key]: value,
            };
            const newArray = [];
            newArray[activeModalIndex] = newObject;
            setPerFeaturesObjectsArray(newArray);
          }
        } else {
          setPerFeaturesObjectsArray(
            perFeaturesObjectsArray.map((a, i) => {
              if (i === activeModalIndex) {
                const newData = { ...a };
                delete newData[key];
                return newData;
              } else {
                return a;
              }
            }),
          );
        }
      } else if (listGroup === 'EXT') {
        if (!Array.isArray(value) || value.length) {
          if (extFeaturesObjectsArray.length) {
            setExtFeaturesObjectsArray(
              extFeaturesObjectsArray.map((a, i) => {
                if (i === activeModalIndex) {
                  return {
                    ...extFeaturesObjectsArray[activeModalIndex],
                    [key]: value,
                  };
                } else {
                  return a;
                }
              }),
            );
          } else {
            const newObject = {
              ...extFeaturesObjectsArray[activeModalIndex],
              [key]: value,
            };
            const newArray = [];
            newArray[activeModalIndex] = newObject;
            setExtFeaturesObjectsArray(newArray);
          }
        } else {
          setExtFeaturesObjectsArray(
            extFeaturesObjectsArray.map((a, i) => {
              if (i === activeModalIndex) {
                const newData = { ...a };
                delete newData[key];
                return newData;
              } else {
                return a;
              }
            }),
          );
        }
      } else {
        // features for specific list
        if (!Array.isArray(value) || value.length) {
          if (
            listFeaturesObjectsArray.length &&
            listFeaturesObjectsArray[activeModalIndex]
          ) {
            setListFeaturesObjectsArray(
              listFeaturesObjectsArray.map((a, i) => {
                if (i === activeModalIndex) {
                  return {
                    ...a,
                    [listGroup]: {
                      ...a[listGroup],
                      [key]: value,
                    },
                  };
                } else {
                  return a;
                }
              }),
            );
          } else {
            const newObject = {
              [listGroup]: {
                [key]: value,
              },
            };
            const newArray = [];
            newArray[activeModalIndex] = newObject;
            setListFeaturesObjectsArray(newArray);
          }
        } else {
          setListFeaturesObjectsArray(
            listFeaturesObjectsArray.map((a, i) => {
              if (i === activeModalIndex) {
                const newData = { ...a };
                delete newData[listGroup][key];
                return newData;
              } else {
                return a;
              }
            }),
          );
        }
      }
    };

    const modalContent = (features, listGroup) => {
      return (
        <Modal.Content key={listGroup}>
          <DoubleBar
            text={'Tempo'}
            listGroup={listGroup}
            initialMinValue={features?.tempo ? features?.tempo[0] : 10}
            initialMaxValue={features?.tempo ? features?.tempo[1] : 220}
            featureActive={features?.tempo?.length > 0}
            min={10}
            max={220}
            step={1}
            handleParentValue={handleFeaturesObject}
            featureName={'tempo'}
          />
          <DoubleBar
            text={'Bailabilidad'}
            listGroup={listGroup}
            initialMinValue={
              features?.danceability ? features?.danceability[0] : 0
            }
            initialMaxValue={
              features?.danceability ? features?.danceability[1] : 1
            }
            featureActive={features?.danceability?.length > 0}
            min={0}
            max={1}
            step={0.01}
            handleParentValue={handleFeaturesObject}
            featureName={'danceability'}
          />
          <DoubleBar
            text={'Energía'}
            listGroup={listGroup}
            initialMinValue={features?.energy ? features?.energy[0] : 0}
            initialMaxValue={features?.energy ? features?.energy[1] : 1}
            featureActive={features?.energy?.length > 0}
            min={0}
            max={1}
            step={0.01}
            handleParentValue={handleFeaturesObject}
            featureName={'energy'}
          />
          <DoubleBar
            text={'Alegría'}
            listGroup={listGroup}
            initialMinValue={features?.valence ? features?.valence[0] : 0}
            initialMaxValue={features?.valence ? features?.valence[1] : 1}
            featureActive={features?.valence?.length > 0}
            min={0}
            max={1}
            step={0.01}
            handleParentValue={handleFeaturesObject}
            featureName={'valence'}
          />
          <DoubleBar
            text={'Nivel instrumental'}
            listGroup={listGroup}
            initialMinValue={
              features?.instrumentalness ? features?.instrumentalness[0] : 0
            }
            initialMaxValue={
              features?.instrumentalness ? features?.instrumentalness[1] : 1
            }
            featureActive={features?.instrumentalness?.length > 0}
            min={0}
            max={1}
            step={0.00001}
            handleParentValue={handleFeaturesObject}
            featureName={'instrumentalness'}
          />
          <DoubleBar
            text={'Nivel acústico'}
            listGroup={listGroup}
            initialMinValue={
              features?.acousticness ? features?.acousticness[0] : 0
            }
            initialMaxValue={
              features?.acousticness ? features?.acousticness[1] : 1
            }
            featureActive={features?.acousticness?.length > 0}
            min={0}
            max={1}
            step={0.001}
            handleParentValue={handleFeaturesObject}
            featureName={'acousticness'}
          />
          <DoubleBar
            text={'Presencia de voz'}
            listGroup={listGroup}
            initialMinValue={
              features?.speechiness ? features?.speechiness[0] : 0
            }
            initialMaxValue={
              features?.speechiness ? features?.speechiness[1] : 1
            }
            featureActive={features?.speechiness?.length > 0}
            min={0}
            max={1}
            step={0.001}
            handleParentValue={handleFeaturesObject}
            featureName={'speechiness'}
          />
          <DoubleBar
            text={'Amplitud en dB'}
            listGroup={listGroup}
            initialMinValue={features?.loudness ? features?.loudness[0] : -30}
            initialMaxValue={features?.loudness ? features?.loudness[1] : 0}
            featureActive={features?.loudness?.length > 0}
            min={-30}
            max={0}
            step={0.001}
            handleParentValue={handleFeaturesObject}
            featureName={'loudness'}
          />
          <DoubleBar
            text={'Popularidad'}
            listGroup={listGroup}
            initialMinValue={features?.popularity ? features?.popularity[0] : 0}
            initialMaxValue={
              features?.popularity ? features?.popularity[1] : 100
            }
            featureActive={features?.popularity?.length > 0}
            min={0}
            max={100}
            step={1}
            handleParentValue={handleFeaturesObject}
            featureName={'popularity'}
          />
          <CheckSelector
            text={'Habilitar explícitas'}
            listGroup={listGroup}
            featureActive={features?.enableExplicit}
            handleParentValue={handleFeaturesObject}
            featureName={'enableExplicit'}
          />
          <DecadeSelector
            text={'Décadas'}
            listGroup={listGroup}
            initialValues={features?.yearRanges ? features?.yearRanges : []}
            featureActive={features?.yearRanges?.length > 0}
            handleParentValue={handleFeaturesObject}
            featureName={'yearRanges'}
          />
        </Modal.Content>
      );
    };

    const handleWeightsSum = (listType, pid, value) => {
      if (listType === 'PER') {
        if (perWeights) {
          const newValue = { ...perWeights, [pid]: parseFloat(value) };
          setPerWeights(newValue);
          setPerWeightsSum(calcWeightsSum(newValue));
        } else {
          setPerWeights({ [pid]: value });
          setPerWeightsSum(calcWeightsSum({ [pid]: value }));
        }
      }
      if (listType === 'EXT') {
        if (extWeights) {
          const newValue = { ...extWeights, [pid]: parseFloat(value) };
          setExtWeights(newValue);
          setExtWeightsSum(calcWeightsSum(newValue));
        } else {
          setExtWeights({ [pid]: value });
          setExtWeightsSum(calcWeightsSum({ [pid]: value }));
        }
      }
    };

    const buildWeightsList = (list, enabled, listType) => {
      return list.map((p) => {
        return (
          <div
            key={activeModalIndex + p.id}
            style={{ display: 'flex', flexDirection: 'row' }}>
            <Input
              key={activeModalIndex + p.id}
              type="number"
              disabled={!enabled}
              min="0"
              minLength={1}
              max="100"
              value={
                listType === 'PER'
                  ? perWeights
                    ? perWeights[p.id]
                    : ''
                  : extWeights
                  ? extWeights[p.id]
                  : ''
              }
              onChange={(e) => {
                handleWeightsSum(listType, p.id, e.target.value);
              }}
            />
            <p style={{ marginLeft: '0.5rem', lineHeight: '2.5rem' }}>
              {p.name}{' '}
              <span style={{ color: 'blue' }}>
                {listFactors
                  ? listType === 'PER'
                    ? listFactors.perListFactors
                      ? parseFloat(listFactors.perListFactors?.[p.id]).toFixed(
                          2,
                        )
                      : null
                    : listFactors.extListFactors
                    ? parseFloat(listFactors.extListFactors?.[p.id]).toFixed(2)
                    : null
                  : null}
              </span>
            </p>
          </div>
        );
      });
    };

    const panes = [
      {
        menuItem: 'Peruanas',
        render: () => (
          <Tab.Pane>
            {modalContent(perFeaturesObjectsArray[activeModalIndex], 'PER')}
          </Tab.Pane>
        ),
      },
      {
        menuItem: 'Extranjeras',
        render: () => (
          <Tab.Pane>
            {modalContent(extFeaturesObjectsArray[activeModalIndex], 'EXT')}
          </Tab.Pane>
        ),
      },
    ];

    const buildPlaylistPanes = () => {
      const perTabs = perIncludedLists.map((p) => {
        return {
          menuItem: p.name,
          render: () => (
            <Tab.Pane key={p.name}>
              {modalContent(
                listFeaturesObjectsArray.length &&
                  listFeaturesObjectsArray[activeModalIndex]
                  ? listFeaturesObjectsArray[activeModalIndex][p.id]
                  : null,
                p.id,
              )}
            </Tab.Pane>
          ),
        };
      });

      const extTabs = extIncludedLists.map((p) => {
        return {
          menuItem: p.name,
          render: () => (
            <Tab.Pane>
              {modalContent(
                listFeaturesObjectsArray.length &&
                  listFeaturesObjectsArray[activeModalIndex]
                  ? listFeaturesObjectsArray[activeModalIndex][p.id]
                  : null,
                p.id,
              )}
            </Tab.Pane>
          ),
        };
      });

      const weightsTab = {
        menuItem: 'Pesos por lista',
        render: () => (
          <Tab.Pane key={'weightsTab' + activeModalIndex}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-around',
              }}>
              <div key={'weightsPER' + activeModalIndex}>
                <Checkbox
                  toggle
                  label={enablePERWeights ? 'Habilitados' : 'Deshabilitados'}
                  style={{
                    marginBottom: 10,
                    fontWeight: 'bold',
                    fontSize: '0.8rem',
                  }}
                  disabled={perIncludedLists.length < 2}
                  checked={enablePERWeights}
                  onClick={() => {
                    if (perIncludedLists.length > 1) {
                      if (!enablePERWeights) {
                        const newObject = {};
                        for (const p of perIncludedLists) {
                          newObject[p.id] = Math.ceil(
                            100 / perIncludedLists.length,
                          );
                        }
                        setPerWeights(newObject);
                        setPerWeightsSum(calcWeightsSum(newObject));
                      } else {
                        setPerWeights(undefined);
                        setPerWeightsSum(100);
                      }
                      setEnablePERWeights(!enablePERWeights);
                    } else {
                      setPerWeights(undefined);
                      setPerWeightsSum(100);
                    }
                  }}
                />
                {buildWeightsList(perIncludedLists, enablePERWeights, 'PER')}
              </div>
              <div key={'weightsEXT' + activeModalIndex}>
                <Checkbox
                  toggle
                  label={enableEXTWeights ? 'Habilitados' : 'Deshabilitados'}
                  style={{
                    marginBottom: 10,
                    fontWeight: 'bold',
                    fontSize: '0.8rem',
                  }}
                  disabled={extIncludedLists.length < 2}
                  checked={enableEXTWeights}
                  onClick={() => {
                    if (extIncludedLists.length > 1) {
                      if (!enableEXTWeights) {
                        const newObject = {};
                        for (const p of extIncludedLists) {
                          newObject[p.id] = Math.ceil(
                            100 / extIncludedLists.length,
                          );
                        }
                        setExtWeights(newObject);
                        setExtWeightsSum(calcWeightsSum(newObject));
                      } else {
                        setExtWeights(undefined);
                        setExtWeightsSum(100);
                      }
                      setEnableEXTWeights(!enableEXTWeights);
                    } else {
                      setExtWeights(undefined);
                      setExtWeightsSum(100);
                    }
                  }}
                />
                {buildWeightsList(extIncludedLists, enableEXTWeights, 'EXT')}
              </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Button
                onClick={() => handleCalculateListFactors()}
                disabled={checkWeightSums()}
                loading={refreshingListFactors}
                color="blue"
                icon="redo"
              />
              {checkWeightSums() ? (
                <p style={{ color: 'red', fontWeight: 'bold' }}>
                  Los pesos no suman 100, sino{' '}
                  {`${perWeightsSum} y ${extWeightsSum}`}
                </p>
              ) : (
                <p></p>
              )}
            </div>
          </Tab.Pane>
        ),
      };
      return [...perTabs, ...extTabs, weightsTab];
    };

    const finalPanes = [...panes, ...buildPlaylistPanes()];

    const handleTabChange = (e, data) => {
      // const finalFeaturesObject = {
      //   perFeatures: perFeaturesObject,
      //   extFeatures: extFeaturesObject,
      // };
      // handleParentFeaturesObject(finalFeaturesObject, activeModalIndex);
    };

    return (
      <>
        <Modal open={openAdvancedModal}>
          <Modal.Header>
            {site.siteDetails.name} -
            {site.playlists[activeModalIndex]?.scheduleAlias} - Filtros
            Avanzados
          </Modal.Header>
          <Tab
            panes={finalPanes}
            renderActiveOnly={true}
            onTabChange={handleTabChange}
          />
          <Modal.Actions>
            <Button
              content="Regresar"
              labelPosition="right"
              icon="checkmark"
              disabled={checkWeightSums()}
              onClick={() => handleCloseAdvancedModal()}
              positive
            />
          </Modal.Actions>
        </Modal>
      </>
    );
  },
);

export default FiltersModal;
