import React from 'react';
import { IconContext } from 'react-icons';
import {
  FaFacebook,
  FaInstagram,
  FaTiktok,
  FaTwitter,
  FaSpotify,
  FaYoutube,
} from 'react-icons/fa';
import '../assets/styles/SocialIcons.css';

const SocialIcons = ({ currentArtist }) => {
  return (
    <div className="socialIcons">
      <div>
        <IconContext.Provider
          value={{
            color: currentArtist?.socialNetworks?.facebookURL
              ? '#3b5998'
              : '#C8C8C8',
            size: '2.5rem',
          }}>
          <a
            href={currentArtist?.socialNetworks?.facebookURL}
            target="_blank"
            rel="noreferrer">
            <FaFacebook />
          </a>
        </IconContext.Provider>
      </div>
      <div>
        <IconContext.Provider
          value={{
            color: currentArtist?.socialNetworks?.instagramURL
              ? '#f73466'
              : '#C8C8C8',
            size: '2.5rem',
          }}>
          <a
            href={currentArtist?.socialNetworks?.instagramURL}
            target="_blank"
            rel="noreferrer">
            <FaInstagram />
          </a>
        </IconContext.Provider>
      </div>
      <div>
        <IconContext.Provider
          value={{
            color: currentArtist?.socialNetworks?.spotifyURL
              ? '#1DB954'
              : '#C8C8C8',
            size: '2.5rem',
          }}>
          <a
            href={currentArtist?.socialNetworks?.spotifyURL}
            target="_blank"
            rel="noreferrer">
            <FaSpotify />
          </a>
        </IconContext.Provider>
      </div>
      <div>
        <IconContext.Provider
          value={{
            color: currentArtist?.socialNetworks?.tiktokURL
              ? 'black'
              : '#C8C8C8',
            size: '2.5rem',
          }}>
          <a
            href={currentArtist?.socialNetworks?.tiktokURL}
            target="_blank"
            rel="noreferrer">
            <FaTiktok />
          </a>
        </IconContext.Provider>
      </div>
      <div>
        <IconContext.Provider
          value={{
            color: currentArtist?.socialNetworks?.twitterURL
              ? '#1DA1F2'
              : '#C8C8C8',
            size: '2.5rem',
          }}>
          <a
            href={currentArtist?.socialNetworks?.twitterURL}
            target="_blank"
            rel="noreferrer">
            <FaTwitter />
          </a>
        </IconContext.Provider>
      </div>
      <div>
        <IconContext.Provider
          value={{
            color: currentArtist?.socialNetworks?.youtubeURL
              ? '#ff0000'
              : '#C8C8C8',
            size: '2.5rem',
          }}>
          <a
            href={currentArtist?.socialNetworks?.youtubeURL}
            target="_blank"
            rel="noreferrer">
            <FaYoutube />
          </a>
        </IconContext.Provider>
      </div>
    </div>
  );
};

export default SocialIcons;
