import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'semantic-ui-react';
import { Context as GeneralContext } from '../context/GeneralContext';
import GlobalSitesView from './GlobalSitesView';
import TracksTable from './TracksTable';

const AdminNew = () => {
  const navigate = useNavigate();
  const {
    getSites,
    state: { sites, tokenIsValid },
  } = useContext(GeneralContext);

  useEffect(() => {
    if (!tokenIsValid) {
      navigate('/');
    }
    getSites();
  }, []);

  return (
    <div className="adminDiv">
      <div
        style={{
          alignSelf: 'flex-end',
          display: 'flex',
        }}>
        <Button
          style={{
            borderRadius: '12.5px',
            verticalAlign: 'middle',
            backgroundColor: '#152AF7',
            color: 'white',
          }}
          disabled={!sites?.length}
          onClick={() => navigate(`/admin/moodcreator/${sites[0].siteID}`)}>
          MOOD CREATOR
        </Button>
      </div>

      <GlobalSitesView sites={sites} />
      <TracksTable />
    </div>
  );
};

export default AdminNew;
