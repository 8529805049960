import React, { useState } from 'react';
import Slider from '@mui/material/Slider';
import { Checkbox } from 'semantic-ui-react';

const DoubleBar = ({
  text,
  initialMinValue,
  initialMaxValue,
  min,
  max,
  step,
  handleParentValue,
  featureName,
  featureActive,
  listGroup,
}) => {
  const [value, setValue] = useState([initialMinValue, initialMaxValue]);
  const [active, setActive] = useState(featureActive);
  const finalValue = !active ? [initialMinValue, initialMaxValue] : value;

  const handleChange = (event, newValue) => {
    setValue(newValue);
    handleParentValue(featureName, newValue, listGroup);
  };

  return (
    <div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
        }}>
        <Checkbox
          toggle
          checked={active}
          onClick={() => {
            setValue([initialMinValue, initialMaxValue]);
            handleParentValue(
              featureName,
              [initialMinValue, initialMaxValue],
              listGroup,
            );
            setActive((m) => !m);
            if (active) {
              handleParentValue(featureName, [], listGroup);
            }
          }}
        />
        <h4 style={{ margin: '0 1rem' }}>
          {'  '} {text}
        </h4>
        <p>
          {featureName === 'instrumentalness' ||
          featureName === 'acousticness' ||
          featureName === 'speechiness'
            ? finalValue[0]?.toFixed(5) + ' - ' + finalValue[1]?.toFixed(5)
            : finalValue[0]?.toFixed(2) + ' - ' + finalValue[1]?.toFixed(2)}
        </p>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-around',
        }}>
        <Slider
          size="small"
          value={value}
          onChange={handleChange}
          min={min}
          max={max}
          step={step}
          valueLabelDisplay="auto"
          disabled={!active}
        />
      </div>
    </div>
  );
};

export default DoubleBar;
