import React, { useState } from 'react';
import Slider from '@mui/material/Slider';
import { Checkbox } from 'semantic-ui-react';

const DoubleBar = ({
  text,
  handleParentValue,
  featureName,
  featureActive,
  listGroup,
}) => {
  const [active, setActive] = useState(featureActive);

  return (
    <div style={{ margin: '1rem 0' }}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
        }}>
        <Checkbox
          toggle
          checked={active}
          onClick={() => {
            // onChange = { handleChange };
            setActive((active) => !active);
            handleParentValue(featureName, !active, listGroup);
          }}
        />
        <h4 style={{ margin: '0 1rem' }}>
          {'  '} {text}
        </h4>
      </div>
    </div>
  );
};

export default DoubleBar;
